import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardAuthContext = createContext();

const DashboardAuthProvider = ({ children }) => {
  const [isDashboardUser, setIsDashboardUser] = useState(false);
  const navigate = useNavigate();

  const accessDashboard = () => {
   
    setIsDashboardUser(true);
    navigate('/dashboard');  
  };

  const leaveDashboard = () => {
    // Lógica para sair do dashboard
    setIsDashboardUser(false);
    navigate('/login');  // Redireciona para a tela de login ou outra tela
  };

  return (
    <DashboardAuthContext.Provider value={{ isDashboardUser, accessDashboard, leaveDashboard }}>
      {children}
    </DashboardAuthContext.Provider>
  );
};

const useDashboardAuth = () => useContext(DashboardAuthContext);

export { DashboardAuthProvider, useDashboardAuth };