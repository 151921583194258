
import HCard from './card';
import HCard2 from './card2';
import HCard3 from './card3';
import '../css/card.css'

function handleButtonClick() {
  window.location.href = '/reg';
}

function GroupCards() {
  return (
    <div>
      <div className='TextGroup'>
        <h1 className='text2'>Esteja mais <h1 className='text2' style={{ backgroundColor: 'yellow', marginLeft: '5px', marginRight: '5px' }}>preparado</h1> que os Hackers</h1>
        <h6 className='subtext2'>Cibersegurança é um risco que não pode ser ignorado. Simplificamos a solução para que você lide com um risco tão complexo com tranquilidade.</h6>

      </div>
      <div className='teste'>
        <HCard />
        <HCard2 />
        <HCard3 />
      </div >
      <div className='buttoncontainer'>
      <button className='ButtonInputcard' onClick={handleButtonClick} >Cadastre-se para obter acesso gratuito à plataforma</button>
      </div>
    </div >
  );
}

export default GroupCards;