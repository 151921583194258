import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import {  ShieldPlus } from 'lucide-react';

function OffCanvasAPS({ name,  status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3 && checkboxes.checkbox4;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>ATUALIZAÇÃO DE PATCHES E SOFTWARES</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p>Essa recomendação de cibersegurança significa que você deve configurar seus dispositivos e softwares de terceiros para atualizarem automaticamente e instalarem os últimos patches (correções) de segurança de forma regular e periódica. </p>

          <h5>Exemplo de um Patch de Segurança:</h5>
          <p>Vamos supor que uma empresa como a Microsoft descobre uma falha de segurança no sistema operacional Windows. Essa falha poderia permitir que um invasor remoto executasse códigos maliciosos no computador da vítima. Uma vez identificada essa falha, a Microsoft desenvolveria um código para corrigir esse problema específico e, em seguida, distribuiria esse código como um "patch de segurança".</p>
          <p>Quando a Microsoft lança o patch, ele é geralmente acompanhado por uma atualização que os usuários podem baixar e instalar. Esta atualização contém as correções necessárias para "fechar" a vulnerabilidade, impedindo assim que seja explorada.</p>
          <br/>
          <h5>Como funciona na prática:</h5>
          <p>    <b className='boldlist'>1.	Identificação da Vulnerabilidade:</b>  A vulnerabilidade é descoberta por pesquisadores de segurança, usuários, ou pela própria equipe de desenvolvedores do software.</p>
          <p>    <b className='boldlist'>2.	Desenvolvimento do Patch:</b>  Os desenvolvedores criam uma correção para o problema, testam para garantir que resolve a vulnerabilidade sem introduzir novos problemas.</p>
          <p>    <b className='boldlist'>3.	Distribuição do Patch:</b>  O patch é então distribuído aos usuários, geralmente através de um sistema de atualização automática ou manual. No caso do Windows, isso é feito através do Windows Update.</p>
          <p>    <b className='boldlist'>4.	Instalação do Patch:</b>  Os usuários instalam o patch (ou ele é instalado automaticamente), protegendo assim seus sistemas contra a exploração dessa vulnerabilidade específica.</p>
          <br/>
          <p>Patches de segurança são essenciais para a manutenção da integridade e segurança de sistemas informáticos e redes, protegendo-os contra ataques conhecidos e ajudando a manter a confiança dos usuários na tecnologia que utilizam.</p>

          <h5>O que é abordado neste item do checklist:</h5>
          <p> <b className='boldlist'>1.	Atualizações automáticas:</b> Isso se refere à configuração dos dispositivos e softwares para baixarem e instalarem atualizações de segurança sem a necessidade de intervenção manual. Isso garante que você sempre tenha as correções mais recentes aplicadas, o que pode proteger contra vulnerabilidades conhecidas. Por exemplo, no Windows, você pode ajustar as configurações em 'Configurações de Atualização do Windows' para automatizar downloads e instalações.</p>

          <p><b className='boldlist'>2.	Cadência regular:</b> Significa garantir que essas atualizações ocorram de forma programada e frequente e monitorar regularmente os dispositivos para garantir que as atualizações estão sendo aplicadas conforme necessário. Pode ser diariamente, semanalmente, mensalmente ou conforme necessário, dependendo da política de segurança da organização ou da recomendação dos desenvolvedores do software.</p>

          <p><b className='boldlist'>3.	Política de Atualização:</b> Definir e documentar uma política clara que especifique como e quando as atualizações de segurança devem ser aplicadas. Isso inclui definir janelas de tempo para atualizações automáticas, garantindo que elas não interfiram com operações críticas.</p>

          <b className='boldlist'>4.	Ferramentas de Gerenciamento de Patch:</b>
          <p>Para dispositivos empresariais: Ferramentas de gerenciamento de patches como o WSUS (Windows Server Update Services) para sistemas Windows, que permite controlar quando e quais atualizações são distribuídas para os dispositivos na rede.</p>
          <p>Para software de terceiros: Softwares como o SCCM (System Center Configuration Manager) ou ferramentas específicas para Mac e Linux também podem ser usados para gerenciar patches de softwares que não são automaticamente atualizados pelo sistema operacional.</p>

          <br/>
          <p>A ideia é minimizar o risco de ataques cibernéticos mantendo seu sistema atualizado com as mais recentes proteções de segurança, reduzindo assim as chances de ser explorado por falhas já conhecidas.</p>

          <br/>

          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Configuramos os dispositivos e softwares para baixarem e instalarem atualizações de segurança em todos os nossos dispositivos (como laptops e celulares)
          <br/>
          <br/>

          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />	Garantimos que essas atualizações ocorram de forma programada e frequente e monitorar regularmente os dispositivos para garantir que as atualizações estão sendo aplicadas conforme necessário, supervisionadas por um profissional de TI

          <br/>
          <br/>

          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          />	Definimos e documentamos uma política clara que especifique como e quando as atualizações de segurança devem ser aplicadas.

          <br/>
          <br/>


          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox4"
            checked={checkboxes.checkbox4}
            onChange={handleCheckboxChange}
          />	Utilizamos Ferramentas de Gerenciamento de Patch <input placeholder='Insira exemplos aqui:'/>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffAPS({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasAPS key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffAPS;