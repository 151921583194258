import React from 'react';
import Sidebar from '../sidebar'
import '../../App.css';
import '../../css/sidebar.css'
import CardList from '../SectionCardMarket';



function MarketDash() {
  return (
    <div>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
    </head>
      <div className="side">
        <Sidebar />
        <div className='CardSection'>
        <CardList />
      </div>
      </div>
    </div>
  );
}

export default MarketDash;
