import React from 'react';
import CardMarket from './cardmap';
import '../css/market.css'
import data from '../database/cards.json'; // Importe o arquivo JSON

const CardList = () => {
  const rows = [];
  let currentRow = [];

  data.forEach((card, index) => {
    currentRow.push(
      <CardMarket key={card.id} title={card.title} content={card.content} imageUrl = {card.imageUrl} />
    );

    // Quando a linha atual atinge 5 cards ou quando chegamos ao final dos cards
    if (currentRow.length === 5 || index === data.length - 1) {
      rows.push(
        <div key={index} className="card-listed">
          {currentRow}
        </div>
      );
      currentRow = []; // Limpa a linha atual
    }
  });

  return <div className='marketdash'>

    <h1 className='titlemark'>Marketplace </h1>

    <p className='barrinhamark'>.</p>
    <h3 className='subtitlemark'>Parceiros incríveis. Descontos imperdíveis.<br/>
    Tem alguma dúvida? Entre em contato com um de nossos consultores,<br/> clique<a href='/' className='linkmarket'> aqui.</a> </h3>
    <div className="card-list">{rows}</div>;
  
  
  </div>
};

export default CardList;
