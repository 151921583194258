import React, { createContext, useState, useContext,  } from 'react';
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();


const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const login = () => {
    // Implemente a lógica de autenticação
    setIsLoggedIn(true);
    navigate('/dashboard');
  };

  const logout = () => {
    // Implemente a lógica de logout
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
