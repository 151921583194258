import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerIcon from './markericon'
import L from 'leaflet'; // Importe o Leaflet diretamente
import '../css/dash.css'

import iconUrl from './img/pinmap.png';

const Mapa = ({ marcadores }) => {

  const customIcon = L.icon({
    iconUrl,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  
  return (
    <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '420px', width: '100%' }} className='mapcontainer'>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marcadores.map((marcador, index) => (
        <Marker key={index} position={[marcador.lat, marcador.lng]} icon={customIcon}>
          <Popup>
            {marcador.texto}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Mapa;
