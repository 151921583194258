import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'

import {
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover
   
} from 'reactstrap';

import { ShieldPlus, Info } from 'lucide-react';

function OffCanvasBackup({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>BACKUP</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
        <p>Essa recomendação significa que você deve fazer cópias de segurança dos seus dados de forma contínua, ou seja, regularmente e de maneira automática, preferencialmente utilizando criptografia, acesso de administrador e armazenamento local.</p>

        <p> <b className='boldlist'>1.	Backup contínuo: </b> Refere-se a realizar cópias de segurança dos seus dados regularmente, sem intervalos muito grandes entre uma cópia e outra. Isso ajuda a garantir que, em caso de perda de dados, você tenha uma versão recente para restaurar.</p>

        <p> <b className='boldlist'>2.	Criptografia:  </b> É um método de proteção que embaralha seus dados de forma que só possam ser lidos por quem possui a chave de descriptografia correta. Isso garante que, mesmo que alguém acesse suas cópias de segurança, não conseguirá entender ou usar seus dados sem a chave adequada.</p>

        <p> <b className='boldlist'>3.	Acesso de administrador:  </b> Significa que apenas pessoas autorizadas, geralmente administradores do sistema, têm permissão para acessar e gerenciar as cópias de segurança. Isso ajuda a proteger os dados contra acessos não autorizados.</p>

        <p> <b className='boldlist'>4.	Armazenamento local: </b> Refere-se a manter as cópias de segurança dos backups desconectados e inacessíveis a partir da rede principal da empresa. Isso pode ser feito das seguintes formas, por exemplo:<br/>
        <b className='boldlist'> a)	De forma física:</b> ao armazenar o backup em dispositivos de armazenamento externos como discos rígidos externos, SSDs ou fitas magnéticas. Ou, ainda, manter cópias de segurança em um local físico separado, como uma instalação de armazenamento segura fora das instalações da empresa; <br/>
        <b className='boldlist'>b)	Isolando sua rede:</b>  criação de uma sub-rede dedicada exclusivamente para backups, com regras de firewall estritas que proíbam qualquer acesso de entrada ou saída da rede principal, exceto o tráfego de backup autorizado. Ou, ainda, utilizar-se de VLANs (VLANs (Virtual Local Area Networks) para segmentar a rede e isolar o tráfego de backup do resto da rede corporativa. <br/>
        <b className='boldlist'>c)	Utilizar serviços de isolamento em nuvem:</b>  utilizar serviços de armazenamento na nuvem que não sejam acessíveis diretamente pela rede corporativa. O acesso a esses serviços deve ser feito através de autenticações seguras e canais de comunicação criptografados que não utilizem as rotas normais de rede da empresa.
        </p>

        <p> <b className='boldlist'>5.	Autenticação independente nas cópias de segurança do backup: </b> Imagine que sua empresa tem uma porta principal com uma fechadura específica que controla quem pode entrar e acessar as informações mais importantes. Agora, imagine que há um cofre na empresa (os backups), onde são guardadas cópias de segurança de todos os documentos e informações importantes. <br/>

        Se alguém mal-intencionado conseguir copiar a chave dessa porta principal, essa pessoa poderia acessar não só os documentos do dia a dia, mas também o cofre. Para evitar isso, colocamos uma fechadura diferente e mais segura nesse cofre, que não usa a mesma chave da porta principal. Assim, mesmo que a chave principal seja comprometida, nosso cofre continua seguro porque requer uma chave diferente, que somente algumas pessoas de confiança possuem.</p>

        <p>Por isso a recomendação de utilizar um sistema de autenticação independente para acesso às cópias de segurança de backup são importantes. Dessa forma, é criada uma barreira adicional contra ataques. Se um invasor comprometer a rede principal, ele ainda não terá acesso automático aos backups, pois eles requerem um sistema de autenticação completamente separado e independente.</p>

        <p>Um exemplo prático de implementar essa recomendação seria método de autenticação baseado em token ou em uma solução de gerenciamento de identidade independente, como por exemplo autenticação baseado em nuvem, como o Auth0, o Okta ou o Google Identity Platform.</p>
        
        <b>Marque as ações implementadas</b>
        <div className='backgroundinputoff'>

        <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Temos backups semanais dos dados sensíveis e sistemas críticos de negócios: 

            <Info className='InfoButton'
              size={17}
              id="PopoverLegacy"
              type="button"
            >
            </Info>
          <div>
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              Isso significa que a empresa está fazendo cópias de segurança dos dados importantes e dos sistemas cruciais para o funcionamento do negócio pelo menos uma vez por semana
              </PopoverBody>
            </UncontrolledPopover>
            </div>

          <br/>
          <br/>

          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />	As cópias de segurança são desconectadas e inacessíveis da nossa rede principal: 

            <Info className='InfoButton'
              size={17}
              id="PopoverLegacy2"
              type="button"
            >
            </Info>
          <div>
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy2"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              Isso indica que as cópias de segurança são armazenadas de forma separada da rede principal da empresa.
              </PopoverBody>
            </UncontrolledPopover>
            </div>

          <br/>
          <br/>

          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          />	Autenticação independente nas cópias de segurança do backup: as cópias de segurança podem ser acessadas por um método de autenticação baseado em token ou em uma solução de gerenciamento de identidade independente.

          <Info className='InfoButton'
              size={17}
              id="PopoverLegacy3"
              type="button"
            >
            </Info>
          <div>
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy3"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              Aqui, a empresa está garantindo que o acesso às cópias de segurança utilizem um sistema de autenticação baseado em token ou em uma solução de gerenciamento de identidade independente, como por exemplo autenticação baseado em nuvem, como o Auth0, o Okta ou o Google Identity Platform. 
              </PopoverBody>
            </UncontrolledPopover>
            </div>

            </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffBackup({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasBackup key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffBackup;