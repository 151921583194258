
import '../css/card3.css'



function Espacamento() {
  return (
    <p className='espacamento-ponto'>.</p>
  );
}

export default Espacamento;