import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import '../css/card3.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { saveData } from '../database/index';

function InputC() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveData(formData); // Salva os dados no banco de dados
      alert('Dados salvos com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      alert('Erro ao salvar dados. Por favor, tente novamente.');
    }
  };

  return (
    <Card className='t5'>
      <div className='TInput'>

      </div>

      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group  controlId="formGridFirstName">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Nome" name="firstName" value={formData.firstName} onChange={handleChange} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group  controlId="formGridLastName">
            <Form.Label>Sobrenome</Form.Label>
            <Form.Control type="text" placeholder="Sobrenome" name="lastName" value={formData.lastName} onChange={handleChange} />
          </Form.Group>
          </Row>

        <Form.Group className="mb-3" controlId="formGridEmail">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" placeholder="Seu melhor E-mail" name="email" value={formData.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridPhoneNumber">
          <Form.Label>WhatsApp</Form.Label>
          <Form.Control placeholder="+55 15 12554-4445" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
        </Form.Group>


        <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Label>Prefiro que entrem em contato:</Form.Label>
          <div className='contatogridcheck'>
          <Form.Check type="checkbox" label="Por Email" />
          <Form.Check type="checkbox" label="Por WhatsApp" className='porwhatsapp' />
          </div>
        </Form.Group>

        <Button variant="primary" type="submit" className='contratbutton'>
          Enviar
        </Button>
      </Form>
    </Card>
  );
}

export default InputC;
