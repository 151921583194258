import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import Sublogin from '../components/main-components/sub-login';


const SubLogiPage = () => {
  return (
    <>
      <Sublogin />
    </>
  );
};

export default SubLogiPage;