import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'

function OffCanvasmfa({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      <Offcanvas show={show} onHide={handleClose} {...props}  className='offteste' >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>MFA</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p className='cabeçalho'>Use autenticação de dois fatores (2FA) é uma recomendação de segurança importante para proteger suas contas online, como e-mails, redes sociais e bancos de dados. A ideia é adicionar uma camada extra de segurança além da sua senha habitual. Vamos entender como isso funciona de maneira simples:</p>
          

          <h4 className='subtitulo'>Entenda</h4>
          <p>Normalmente, quando você entra em uma conta, precisa inserir sua senha. Essa é a primeira "camada" de segurança. A autenticação de dois fatores (2FA) adiciona uma segunda camada, que requer algo mais além da senha para acessar sua conta. Isso pode ser um código enviado para o seu celular, um aplicativo que gera códigos temporários, ou até mesmo um dispositivo físico que você precisa conectar ao computador.</p>

          <p>Essa segunda camada ajuda a garantir que, mesmo que alguém descubra sua senha, ainda precisará desse segundo fator para realmente acessar sua conta. Assim, suas informações ficam muito mais protegidas contra acessos não autorizados.</p>

          <ul>
          Existem várias ferramentas de autenticação de dois fatores que você pode usar para proteger suas contas. Aqui estão alguns exemplos populares:<br/>
          <br/>
          <li><b>1. </b><b className='boldlist'> Google Authenticator:</b> Um aplicativo que gera códigos de segurança temporários no seu telefone. Você usa esses códigos para fazer login nas suas contas junto com sua senha.</li><br/>
          <li><b>2. </b><b className='boldlist'> Authy:</b> Semelhante ao Google Authenticator, o Authy também gera códigos de segurança temporários e oferece a vantagem de backups encriptados e a possibilidade de acessar seus códigos em múltiplos dispositivos.</li><br/>
          <li><b>3. </b><b className='boldlist'> Microsoft Authenticator:</b> Outro aplicativo que funciona como o Google Authenticator, fornecendo códigos de segurança temporários que você usa durante o processo de login.</li><br/>
          <li><b>4. </b><b className='boldlist'> SMS e chamadas de voz:</b> Algumas contas oferecem a opção de enviar um código de segurança para o seu telefone via SMS ou chamada de voz, que você então digita para acessar sua conta.</li><br/>
          <li><b>5. </b><b className='boldlist'> Chaves de segurança físicas:</b> Dispositivos como o YubiKey funcionam como uma chave física para suas contas online. Você insere a chave USB no seu computador ou a conecta via NFC ao seu telefone para provar sua identidade.</li><br/>
          </ul>
          <br />
          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          /> Utilizamos MFA (autenticação multifatorial) em todo o software de gerenciamento remoto, VPNs e firewalls <br/>
          <br />
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          /> A autenticação de dois fatores (2FA) é implementada para sistemas essenciais<br/>
          <br />
          <input className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          /> O acesso remoto à rede é concedido somente mediante autenticação de 2 fatores<br/>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}



function OffMFA({ status, toggleStatus }) {
  return (
    <>
      {['end'].map((placement, idx) => (
        <OffCanvasmfa
        key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus}
        />
      ))}
    </>
  );
}

export default OffMFA;