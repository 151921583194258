import React from 'react';
import { useNavigate } from 'react-router-dom';
import sideimg1 from '../components/img/photos/side1.png';
import sideimg2 from '../components/img/photos/side2.png';
import sideimg3 from '../components/img/photos/side3.png';
import sideimg4 from '../components/img/photos/side4.png';
import logocat from '../components/img/photos/logocat.png';
import '../css/app.css';
import { useDashboardAuth } from '../components/js/dashAuth'; 

const Sidebar = () => {
  const navigate = useNavigate(); // Hook do React Router
  const { accessDashboard } = useDashboardAuth(); 

  const handleDashboardAccess = () => {
    accessDashboard(); // Acessa o dashboard
    navigate('/dashboard'); // Navega para a rota do dashboard
  };

  return (
    <div className='widhtfix'>
      <div className='background'>
        <div className='fix'>
          <nav id="sidebar" className="">
            <div className="sidebar-content js-simplebar">
              <a className="sidebar-brand" href="/">
                <img className='imgtop' src={logocat} alt="Logo" />
              </a>

              <ul className="sidebar-nav">
                <li className="sidebar-header">
                  Pages
                </li>

                <li className="sidebar-item">
                  {/* Altera o comportamento do link */}
                  <div className="sidebar-link" onClick={handleDashboardAccess}>
                    <img className='imgside' src={sideimg1} alt="Logo" />  
                    <span className="align-middle">Painel de Risco</span>
                  </div>
                </li>

                <li className="sidebar-item">
                  <a className="sidebar-link" href="/check">
                    <img className='imgside' src={sideimg2} alt="Logo" />  
                    <span className="align-middle" id='Side-item'>CheckList</span>
                  </a>
                </li>

                <li className="sidebar-header">
                  CatEye - Services
                </li>

                <li className="sidebar-item">
                  <a className="sidebar-link" href="/services">
                    <img className='imgside' src={sideimg3} alt="Logo" />  
                    <span className="align-middle">Nossos Planos</span>
                  </a>
                </li>

                <li className="sidebar-item">
                  <a className="sidebar-link" href="/copilot">
                    <img className='imgside' src={sideimg4} alt="Logo" />  
                    <span className="align-middle">Security Copilot</span>
                  </a>
                </li>

              </ul>

              <div className="sidebar-cta"></div>
            </div>
          </nav>
        </div>
      </div>
      <div className='goddidi'></div>
    </div>
  );
};

export default Sidebar;
