import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import olho from '../components/img/photos/olho.png'

function OffCanvasCCA({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3 && checkboxes.checkbox4;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>CRIPTOGRAFIA E CONTROLE DE ACESSO </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p>Essa recomendação de cibersegurança é voltada para proteger os dispositivos utilizados por funcionários em uma organização, garantindo que as informações sensíveis sejam guardadas de forma segura. Vamos dividir a explicação em duas partes, conforme os pontos destacados:</p>

          <p><b className='boldlist'>1.	Controle de Acesso:</b> Remoção de privilégios de administrador dos laptops dos usuários</p>
          <p>Normalmente, contas de administrador em computadores têm permissões para instalar software, alterar configurações do sistema e acessar todos os arquivos no dispositivo. Embora isso seja útil, também pode ser um risco de segurança se mal utilizado ou explorado por malware (software malicioso). Ao remover esses privilégios de administrador dos laptops dos funcionários, você limita o que pode ser feito sem autorização específica. Isso ajuda a prevenir a instalação de software malicioso e outras alterações prejudiciais ao sistema.</p>
          <br/>

          <p><b className='boldlist'>2.	Criptografia dos discos de todos os dispositivos dos funcionários</b></p>
          <p>A criptografia é uma técnica que codifica os dados de tal forma que apenas pessoas com a chave correta podem acessá-los. Ao criptografar o disco rígido de um dispositivo, você garante que as informações armazenadas nele estejam seguras e protegidas contra acesso não autorizado, mesmo que o dispositivo seja perdido ou roubado. Com a criptografia, os dados ficam ilegíveis sem a chave de criptografia apropriada.<br/>Soluções como BitLocker (para Windows) e FileVault (para macOS) são comumente usadas.</p>
          <br/>

          <p><b className='boldlist'>3.	Manter Softwares Atualizados:</b> </p>
          <p>Garanta que todos os dispositivos estejam rodando as versões mais recentes de seus sistemas operacionais e softwares de segurança, para aproveitar as melhorias de segurança e correções de vulnerabilidades.</p>
          <br/>

          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Todos os usuários são impedidos de ter acesso administrativo em seus dispositivos, como laptops. Ou seja, contas com privilégios de administrador, que podem fazer alterações significativas nos sistemas, como instalar ou desinstalar software, alterar configurações do sistema operacional, acessar todos os arquivos no dispositivo, entre outros, são removidos das contas regulares de todos os funcionários.
          <br/>
          <br/>

          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />	Utilizamos ferramenta de criptografia confiável para criptografar os discos rígidos de todos os dispositivos de funcionários.

          <br/>
          <br/>

          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          />	Orientamos aos funcionários e garantimos que todos os softwares de nossos dispositivos estejam rodando com as versões mais atualizadas de seus sistemas operacionais

          <br/>
          <br/>


          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox4"
            checked={checkboxes.checkbox4}
            onChange={handleCheckboxChange}
          />	Dados Pessoais ou Dados Sensíveis estão sempre criptografados em nossa rede

          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffCCA({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasCCA key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffCCA;