import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleEmail({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Exposição de E-mail em Vazamentos (Email Breach Message)</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>Descrição:</b>
            <p>"Email Breach Message" é um alerta que indica que um ou mais endereços de e-mail associados ao seu domínio foram expostos em uma violação de dados. Isso significa que esses e-mails podem ter sido roubados por hackers em algum incidente de segurança.
            <br/><br/>
            Quando seus e-mails são expostos, os cibercriminosos podem tentar acessar suas contas, enviar e-mails falsos (phishing) para enganar você ou seus contatos, ou até mesmo roubar sua identidade.
          
            </p>
          </div>

          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Riscos Envolvidos: </b>
                <ul>
                <li className='sublista'><b>Phishing: </b> Com seus e-mails comprometidos, cibercriminosos podem enviar e-mails fraudulentos para enganar você ou seus funcionários, tentando roubar informações ou espalhar malware.</li>
                <li className='sublista'><b>Acesso Não Autorizado: </b> Se as senhas associadas aos e-mails comprometidos forem fracas ou reutilizadas, há o risco de invasores acessarem contas e serviços onde esses e-mails são usados.</li>
                <li className='sublista'><b>Roubo de Identidade: </b> Os dados expostos podem ser usados por criminosos para tentar se passar por você ou seus funcionários, causando potenciais danos financeiros e reputacionais.</li>
                </ul>
                </li>
              <br/>
              <li className=''><b>2.	Origem do Problema:  </b> A violação de dados que comprometeu esses e-mails pode ter ocorrido em algum serviço onde os e-mails foram usados para cadastro, como redes sociais, serviços de nuvem, fóruns, ou qualquer outro site que possa ter sido atacado.</li>
             
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Troque as senhas dos e-mails afetados.</a></li>
              <li className='lista'><a>Ative a autenticação de dois fatores (2FA).</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashEmail() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleEmail key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashEmail