import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleCES({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Validade do Certificado de Segurança (Certificate Expiry Status)</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b></b>
            <p>O termo "Certificate Expiry Status" em um scan de cibersegurança refere-se à data de validade do certificado SSL/TLS do seu site. Esse certificado é essencial para garantir que as informações trocadas entre os visitantes e o site sejam criptografadas e, portanto, protegidas.
            </p>
            <b>O que significa "Certificate Expiry Status"?</b>
          </div>

          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Validade do Certificado:  </b>Todo certificado SSL/TLS tem uma data de validade, geralmente de 1 ou 2 anos. O "Certificate Expiry Status" é um alerta que informa se o certificado está perto de expirar, já expirou, ou ainda está dentro do prazo de validade.</li>
              <br/>
              <li className=''><b>2.	Problemas ao Expirar:  </b>Se o certificado expirar, os navegadores começarão a mostrar avisos de segurança para os visitantes, dizendo que o site não é seguro. Isso pode fazer com que as pessoas evitem acessar o site, o que pode prejudicar a reputação e a confiabilidade da empresa.</li>
              <br/>
              <li className=''><b>3.	Implicações de Segurança:  </b>Um certificado expirado significa que a segurança do site pode estar comprometida. A criptografia ainda pode funcionar, mas como o certificado não é mais válido, os visitantes não têm garantias de que estão realmente conectados ao seu site e não a um site malicioso.</li>
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Renove o certificado antes que ele expire.</a></li>
              <li className='lista'><a>Instale o novo certificado no servidor.</a></li>
              <li className='lista'><a>Configure a renovação automática.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashCES() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleCES key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashCES