import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import '../css/perfil.css';
import Cookies from 'js-cookie';

function OffCanvasaltPass({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [newPassword, setNewPassword] = useState('');  // Estado para a nova senha
  const [confirmPassword, setConfirmPassword] = useState('');  // Estado para confirmação de senha
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Funções para lidar com a alteração das senhas
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  // Função para validar os critérios da senha
  const validatePassword = (password) => {
    const minLength = password.length >= 12;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    return minLength && hasLowercase && hasUppercase && hasNumber;
  };

  // Função para validar e enviar ao backend
  const handleSubmit = async () => {
    // Verifica se as senhas coincidem
    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem. Tente novamente.');
      return;
    }

    // Valida os critérios da nova senha
    if (!validatePassword(newPassword)) {
      setError('A senha deve ter no mínimo 12 caracteres, incluir pelo menos uma letra minúscula, uma letra maiúscula e um número.');
      return;
    }

    const accessToken = Cookies.get('access_token');  // Recupera o JWT do cookie
  
    try {
      const response = await fetch('https://api2.cateyecyber.com/update_password', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`  // Adiciona o token JWT ao cabeçalho
        },
        body: JSON.stringify({ new_password: newPassword })
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Senha atualizada com sucesso!');
        setError(null);
        setShow(false);  // Fecha o offcanvas após sucesso
      } else {
        setError(data.error || 'Erro ao atualizar a senha.');
        setSuccessMessage('');
      }
    } catch (error) {
      setError('Erro de rede ao tentar atualizar a senha.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow} className="me-2" id="button-addon2">
        Alterar
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props} placement='top'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Altere sua Senha:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='altbody'>
          Faça a Alteração da sua Senha Abaixo:
        
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Nova Senha:</InputGroup.Text>
            <Form.Control
              placeholder="Insira sua nova Senha aqui!"
              aria-label="Password"
              aria-describedby="basic-addon1"
              type="password"  // Tipo de campo para senha
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Confirme a Senha:</InputGroup.Text>
            <Form.Control
              placeholder="Certifique-se de colocar exatamente a mesma senha"
              aria-label="Confirm Password"
              aria-describedby="basic-addon1"
              type="password"  // Tipo de campo para senha
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </InputGroup>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <div className='confirmbutton'>
            <Button variant="success" onClick={handleSubmit}>
              Confirmar Alteração
            </Button>
          </div>
          
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasaltPass;
