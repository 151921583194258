
import Card from 'react-bootstrap/Card';
import '../css/card.css'
import card1 from '../components/img/photos/card1.png'

function HCard() {
  return (

    <Card className='CardMain'>
      
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>
      <img alt='img' width={30} height={30} className='imgcard2' src={card1} ></img>
        <Card.Title className='cardtitle'>Informação</Card.Title>

        <Card.Title className='subtitle'>Entenda como está o risco da sua empresa</Card.Title>
        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Nós fazemos o diagnóstico, você toma as decisões. Mergulhamos no ecossistema digital da sua empresa para identificar riscos e vulnerabilidades e te oferecemos um relatório completo, para que você possa traçar o melhor plano de ação.
        <br />
        <br />
        Cada empresa é única, e o seu plano de segurança também deve ser.
        </Card.Text>



      </Card.Body>
    </Card>
  );
}

export default HCard;