import React from 'react';
import '../css/app.css'
import SolucoesPage from '../components/main-components/solucoes';


const Solucoes = () => {
  return (
    <>
      <SolucoesPage />
    </>
  );
};

export default Solucoes;