import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Segurança da Conexão (HTTPS)</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>Descrição</b>
            <p>O termo "HTTPS Status" em um scan de cibersegurança se refere ao estado de segurança de uma página da web que usa o protocolo HTTPS.
            <br/><br/>
            HTTPS (HyperText Transfer Protocol Secure) é a versão segura do HTTP, que é o protocolo utilizado para transferir informações entre o navegador (como o Chrome ou Firefox) e o site que você está acessando. Quando um site usa HTTPS, isso significa que as informações trocadas entre o usuário e o site são criptografadas, ou seja, são embaralhadas de forma que apenas o destinatário pretendido possa lê-las. Isso protege as informações contra interceptações por parte de terceiros.
            <br/><br/>
            Agora, HTTPS Status no contexto de uma vulnerabilidade pode indicar que o site tem algum problema relacionado à implementação ou à configuração do HTTPS. Isso pode incluir situações como:
            </p>
          </div>

          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Certificado Expirado ou Inválido: </b>O certificado digital, que garante a segurança do HTTPS, pode estar vencido ou mal configurado. Isso significa que o site pode não estar protegendo corretamente os dados dos usuários.</li>
              <br/>
              <li className=''><b>2.	Uso de Protocolos Inseguros: </b>O site pode estar permitindo conexões que não são seguras ou usando versões antigas do protocolo HTTPS, que têm vulnerabilidades conhecidas.</li>
              <br/>
              <li className=''><b>3.	Certificado Autoassinado: </b>Em vez de usar um certificado de uma Autoridade Certificadora confiável, o site usa um certificado que ele mesmo criou. Isso não é considerado seguro porque os navegadores não confiam automaticamente nesse tipo de certificado.</li>
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Verifique se o certificado de segurança está válido.</a></li>
              <li className='lista'><a>Renove o certificado, se necessário.</a></li>
              <li className='lista'><a>Configure o site para aceitar apenas conexões seguras (HTTPS).</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDefault() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDefault