
import Card from 'react-bootstrap/Card';
import '../css/card.css'
import card2 from '../components/img/photos/card2.png'

function HCard2() {
  return (

    <Card  className='CardMain'>
      
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>
      <img alt='img' width={30} height={30} className='imgcard2' src={card2} ></img>
        <Card.Title className='cardtitle'>Mitigação</Card.Title>

        <Card.Title className='subtitle'>Monitoramento contínuo para evitar que ataques aconteçam</Card.Title>
        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Ameaças cibernéticas são um problema dinâmico, e sua a solução para esse risco também deve ser. Oferecemos um monitoramento ativo do seu ambiente digital para que sua empresa esteja sempre um passo à frente dos hackers, antecipando e respondendo prontamente às novas ameaças conforme elas surgirem
        </Card.Text>



      </Card.Body>
    </Card>
  );
}

export default HCard2;