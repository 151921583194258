import { useState,useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import olho from '../components/img/photos/olho.png'

function OffCanvasSE({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };


  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>SEGURANÇA DE E-MAIL</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p>Para garantir que as contas de e-mail sejam muito seguras, são necessários métodos de autenticação mais fortes e configuração correta de ferramentas que ajudam a proteger o domínio e a autenticidade dos e-mails enviados e recebidos.</p>
          
          <h4 className='subtitulo'>Entenda</h4>
          <p><b className='boldlist'>•	MFA (Autenticação Multifator):</b> É uma forma de proteger suas contas online além do uso de apenas uma senha. Ao ativar a MFA, você precisa fornecer dois ou mais comprovantes de sua identidade para acessar sua conta. Esses comprovantes podem incluir algo que você sabe (uma senha), algo que você tem (um telefone que recebe um código de verificação), ou algo que você é (impressão digital). A recomendação sugere que todas as contas, incluindo as contas de administradores que têm mais poderes no sistema, devem usar MFA para acessar e-mails. Isso aumenta muito a segurança, pois torna mais difícil para alguém acessar sua conta apenas sabendo sua senha.</p>

          <p><b className='boldlist'>•	Escolher uma ferramenta de segurança adequada para filtrar os e-mails:</b> Dependendo do serviço de e-mail que sua empresa utiliza, você pode ativar configurações avançadas de segurança. Por exemplo, se você usa Gmail, pode ativar a proteção avançada contra phishing e malware. Se usa o Outlook como parte do Microsoft 365, pode optar pelo Microsoft Defender para Office 365.</p>

          <p><b className='boldlist'>•	Configurar os filtros de e-mail:</b> Ajuste as configurações de segurança na ferramenta de e-mail para filtrar anexos suspeitos e links maliciosos. Isso geralmente envolve configurar regras que determinam quais tipos de e-mails são bloqueados ou marcados como suspeitos.</p>
          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Todas as contas de e-mail estão configuradas com MFA (Autenticação Multifator).

          <br/>
          <br/>
          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />	Ajustamos as configurações de segurança na ferramenta de e-mail para filtrar anexos suspeitos e links maliciosos

          <br/>
          <br/>

          
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          />	Utilizamos ferramenta de segurança adequada para filtrar os e-mails, como por exemplo, Microsoft Defender – <input placeholder='Insira exemplos aqui:' />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffSE({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasSE key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffSE;