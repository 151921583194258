

import SCard from './solcard1';
import SCard2 from './solcard2';
import SCard3 from './solcard3';

import '../css/card.css'

function handleButtonClick() {
  window.location.href = '/reg';
}

function GroupCardsSol() {
  return (
    <div>
      <div className='TextGroup2'>
        <h6 className='subtext3'>O que você faria se a sua empresa sofresse um ataque cibernético hoje?</h6>
        <h6 className='subtext4'>com a nossa plataforma você pode encontrar a resposta para essa pergunta.</h6>

      </div>
      <div className='teste'>
        <SCard />
        <SCard2 />
        <SCard3 />
      </div >
      <div className='buttoncontainer'>
        <h6 className='textbuttoncontainer'>Acesse agora a versão gratuita da nossa plataforma <br/> - é só completar o cadastro para ter acesso à versão grátis. </h6>
        <h8 className='subtextbuttoncontainer'>(nos comprometemos a não encher a sua caixa de e-mail com mensagens indesejadas!) </h8>
      <button className='ButtonInputcard'onClick={handleButtonClick} >Cadastre-se para obter acesso gratuito à plataforma</button>
      </div>
    </div >
  );
}

export default GroupCardsSol;