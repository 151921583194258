import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../css/dash.css'
import InputPerfil from './Inputperfil';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="none" onClick={handleShow} className="me-2" id='perfilbutton' >
        Perfil
      </Button>
      <Offcanvas className="offcanvas2"  show={show}   {...props}>
        <Offcanvas.Header closeButton >
          <Offcanvas.Title>Aqui você pode customizar seu perfil .....</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offbody'>
        <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Seu Username :</InputGroup.Text>
        <Form.Control
          placeholder="Novo Username"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Seu Telefone:</InputGroup.Text>
        <Form.Control
          placeholder="Novo Telefone"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Sua Senha:</InputGroup.Text>
        <Form.Control
          placeholder="Sua nova Senha"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>

          <Button variant='success'>Alterar</Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function PerfilModal() {
  return (
    <>
      {['top'].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement}  name={placement} />
      ))}
    </>
  );
}

export default PerfilModal