
import '../css/card.css'
import '../css/politica.css'

function GroupCardP() {
  return (
    <div className='backgroundpolitic'>
      <div className='TextGroup'>
        <h1 className='text2'>Politica de Privacidade e <h1 className='text2' style={{ backgroundColor: 'yellow', marginLeft: '5px', marginRight: '5px' }}>Proteção</h1> de dados</h1>
       
      </div>

    <div className='grid-s'>
      <div className='s'>
        <h1 className='title-p'>1. INTRODUÇÃO</h1>
        <h4 className='text-p'>1.1 A "CAT EYE CYBER", em conformidade com a Lei nº 13.709/2018 - Lei Geral de Proteção de Dados (“LGPD”), respeita a sua privacidade e se compromete em proteger os dados pessoais dos seus usuários. Esta política especifica as práticas de tratamento de dados adotadas pela "CAT EYE CYBER", respaldada nos artigos da LGPD.</h4>
      </div >
      <div className='s'>
        <h1 className='title-p'>2. COLETA DE DADOS (Art. 7º da LGPD)</h1>
        <h4 className='text-p'><ul>2.1 Coletamos seus dados pessoais quando:</ul><br />
        <li className='li-P'>a) Preenche seus dados em nosso site, na seção “CONTATO”;</li><br />
        <li className='li-P'>b) Utiliza nossos serviços;</li> <br />
        <li className='li-P'>c) Participa de pesquisas ou promoções;</li><br />
        <li className='li-P'>d) Comunica-se conosco; </li><br />
        <li className='li-P'>e) Encaminha seus dados mediante preenchimento de qualquer formulário, ou envio de qualquer informação via e-mail, quando solicita nossos serviços de consultoria ou cotação para contratação de seguros;</li><br />
        <li className='li-P'>f) Coletamos dados públicos de sua empresa, para realização de análise de informações de segurança necessárias para avaliação do seu ambiente de segurança cibernético;</li><br />
        <li className='li-P'>g) Mediante solicitação expressa do cliente e após o envio de consentimento prévio, feito por formulário específico, realizamos testes para avaliação de segurança cibernética de sua empresa  e coletamos informações descritas no formulário específico.</li>
      </h4>
      </div >
      <div className='s'>
        <h1 className='title-p'>3. DADOS QUE COLETAMOS (Art. 9º da LGPD)</h1>
        <h4 className='text-p'>3.1. Os dados pessoais podem incluir nome, endereço, e-mail, telefone, entre outros relevantes para as finalidades listadas neste documento.</h4>
        <h4 className='text-p'>3.2. Para a avaliação de riscos cibernéticos, solicitamos dados específicos, mediante envio de consentimento prévio pelo cliente. Informações sobre os dados coletados estão descritos no Termo de Consentimento de Coleta de Dados, documento que detalha os dados coletados e qual a finalidade de sua coleta.</h4>
      </div >
      <div className='s'>
        <h1 className='title-p'>4. FINALIDADE E BASE LEGAL DO TRATAMENTO (Art. 7º e Art. 10 da LGPD)</h1>
        
        <h4 className='text-p'><ul>Tratamos seus dados pessoais para:  </ul><br />
        <li className='li-P'>a) Execução de contrato ou procedimentos preliminares relacionados a prestação de serviços;</li><br />
        <li className='li-P'>b) Cumprimento de obrigação legal ou regulatória;</li> <br />
        <li className='li-P'>c) Exercício regular de direitos em processo judicial, administrativo ou arbitral;</li><br />
        <li className='li-P'>d) Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li><br />
        <li className='li-P'>e) Atender interesses legítimos da CAT EYE CYBER;</li><br /></h4>
        
      </div >

      <div className='s'>
        <h1 className='title-p'>5. CONSENTIMENTO (Art. 8º da LGPD)</h1>
        <h4 className='text-p'>5.1 Quando necessário, solicitaremos o seu consentimento expresso para tratar seus dados. Você tem o direito de revogar seu consentimento a qualquer momento, sem comprometer a legalidade do tratamento realizado com base no consentimento previamente dado. Em caso de revogação de consentimento, este deverá ser encaminhado expressamente, via e-mail, pelo titular dos dados, para contato@cateyecyber.com.</h4>
        <h4 className='text-p'>5.2 É dispensada a exigência do consentimento previsto para os dados tornados manifestamente públicos pelo titular.</h4>
      </div >

      <div className='s'>
        <h1 className='title-p'>6. SEGURANÇA DOS DADOS (Art. 46 da LGPD)</h1>
        <h4 className='text-p'>6.1 Implementamos medidas técnicas e organizacionais apropriadas para proteger seus dados contra perdas, alterações, acesso não autorizado e outras formas indevidas de tratamento.</h4>
      </div >

      <div className='s'>
        <h1 className='title-p'>7. SEUS DIREITOS (Art. 18 da LGPD)</h1>
        <h4 className='text-p'><ul>7.1 De acordo com a LGPD, você tem o direito de:</ul><br />
        <li className='li-P'>a) Confirmar a existência de tratamento;</li><br />
        <li className='li-P'>b) Acessar seus dados;</li> <br />
        <li className='li-P'>c) Corrigir dados incompletos, inexatos ou desatualizados;</li><br />
        <li className='li-P'>d) Anonimizar, bloquear ou eliminar dados desnecessários, excessivos ou tratados em desconformidade;</li><br />
        <li className='li-P'>e) Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa;</li><br />
        <li className='li-P'>f) Eliminar os dados tratados com o consentimento do titular;</li><br />
        <li className='li-P'>g) Obter informações sobre entidades públicas ou privadas com as quais a controladora compartilhou seus dados;</li><br />
        <li className='li-P'>h) Revogação do consentimento.</li><br /></h4>

      </div >
      <div className='s'>
        <h1 className='title-p'>8. ATUALIZAÇÕES DA POLÍTICA</h1>
        <h4 className='text-p'>8.1 Reservamo-nos o direito de atualizar esta política periodicamente. A versão atualizada estará sempre disponível em nosso site/aplicativo, conforme o Art. 48 da LGPD.</h4>
      </div >

      <div className='s'>
        <h1 className='title-p'>9. CONTATO (Art. 23 da LGPD)</h1>
        <h4 className='text-p'>Para dúvidas, solicitações ou reclamações relacionadas aos seus dados pessoais, entre em contato: contato@cateyecyber.com.</h4>

        <h4 className='text-p'>Ao clicar em “Continuar”, o usuário autoriza a CAT EYE CYBER, de forma livre e expressa, a coletar, usar, armazenar, tratar seus dados, conforme informação constante de sua Política de Privacidade e Proteção de Dados.</h4>
      </div >
      </div>
    </div >
  );
}

export default GroupCardP;