import '../css/card3.css'
import TextCardP from './card-politic';


function PoliticCard() {
  return (
    <div>
      <div className="backgrounds">
        <div className="cardSE">
          <TextCardP />
        </div>
      </div>
    </div>

  );
}

export default PoliticCard;