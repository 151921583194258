import React, { useState, useEffect } from 'react';

const Grafico1 = ({ value, threshold }) => {
  const [progressColor, setProgressColor] = useState('#00ff00'); // Cor inicial
  const [progressWidth, setProgressWidth] = useState(0); // Largura inicial

  useEffect(() => {
    // Atualizar cor da progressão
    var newColor = '#41BE64 '; // Cor padrão
    if (value >= threshold) {
      newColor = '#FA4C55'; // Cor vermelha se valor for maior ou igual ao limiar
    } else if (value >= threshold / 2) {
      newColor = '#F1C31E '; // Cor amarela se valor for maior ou igual à metade do limiar
    }
    setProgressColor(newColor);

    // Calcular largura da progressão
    const newWidth = (value / 250) * 180; // Valor máximo de 180 graus
    setProgressWidth(newWidth);
  }, [value, threshold]);

  return (
    <svg viewBox="0 0 100 40" width="650" height="285" xmlns="http://www.w3.org/2000/svg" className='grafico-gauge'>
      <g transform="sc">
        <g transform="rotate(180 50 25)">
          <defs>
            <clipPath id="clipPath">
              <rect x="0" y="0" width="50%" height="100%" />
            </clipPath>
          </defs>
          <circle cx="50" cy="25" r="24" fill="none" />
          <circle cx="50" cy="25" r="24" fill="none" stroke="#f0f0f0" strokeWidth="6" />
          <circle cx="50" cy="25" r="24" fill="none" stroke={progressColor} strokeWidth="6" strokeDasharray={`${progressWidth}, 150`} />

          <rect x="20" y="0" width="60" height="25" fill="#FEFAEE" />
          <g transform="rotate(180 50 25)">
          <text x="50" y="26" textAnchor="middle" fontSize="12" fill="#222E3C">{value}%</text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Grafico1;
