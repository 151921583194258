import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import olho from '../components/img/photos/olho.png'

function OffCanvasTF({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>TREINAMENTO DE FUNCIONÁRIOS</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <h5>O treinamento em cibersegurança é importante por várias razões:</h5>

          <p><b className='boldlist'>1.	Aumenta a Conscientização:</b> Ajuda os funcionários a reconhecerem e entenderem as ameaças cibernéticas, tornando-os mais vigilantes e preparados para identificar e evitar possíveis ataques.</p>

          <p><b className='boldlist'>2.	Reduz o Risco de Ataques Cibernéticos:</b> Ao educar os funcionários sobre como agir de forma segura online, a organização diminui a probabilidade de sofrer ataques cibernéticos, como phishing, malware e outras formas de intrusão.</p>

          <p><b className='boldlist'>3.	Cumprimento de Regulamentações:</b> Muitas indústrias têm regulamentações que exigem medidas de segurança cibernética. O treinamento ajuda a garantir que a organização esteja em conformidade com essas leis e regulamentações, evitando multas e sanções.</p>

          <p><b className='boldlist'>4.	Melhora as Práticas de Segurança:</b> Promove a adoção de melhores práticas de segurança, como a criação de senhas fortes, a atualização regular de softwares e a adoção de medidas de segurança física e lógica.</p>

          <p><b className='boldlist'>5.	Cria uma Cultura de Segurança:</b> Fomenta uma cultura organizacional onde todos os funcionários entendem a importância da segurança cibernética e estão comprometidos em proteger os ativos da empresa.</p>

          <p><b className='boldlist'>6.	Protege Informações Sensíveis:</b> Ao manter os funcionários informados sobre as ameaças e as melhores práticas, a organização protege melhor suas informações sensíveis, reduzindo o risco de violações de segurança que podem ser caras e prejudiciais.</p>

          <p>Esses fatores combinados ajudam a fortalecer a segurança geral da organização, minimizando riscos e promovendo um ambiente de trabalho mais seguro e protegido.</p>

          <br/>
          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Nossa empresa promove treinamento em cibersegurança anualmente a todos os funcionários.

</div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffTF({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasTF key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffTF;