import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import '../../css/text.css'
import Cfooter from '../Hfooter';
import SolucoesCard from '../solucoes-card copy';
import GroupCardsSol from '../grouptextsol';


function SolucoesPage() {
  return (
    <div>
      <HNavbar className='bgc'  />
      <SolucoesCard />
      <div className='groupsolution'>
      <div className='grouptext'>

      <div className='textoffgroup'>
        <h2 className='h2off'>59 %</h2>
        <h5>das organizações brasileiras relatam ao 
          menos um incidente cibernético em 2023</h5>
      </div>

      <div className='textoffgroup'>
        <h2 className='h2off'>115 dias</h2>
        <h5>é o tempo médio que uma empresa leva para conter vazamentos de dados</h5>
      </div>

      <div className='textoffgroup'>
        <h2 className='h2off'>23 bilhões</h2>
        <h5>de ataques cibernéticos foram realizados no Brasil no primeiro semestre de 2023</h5>
      </div>

      </div>
        <GroupCardsSol className='boxshadow' />
      </div>
      <Cfooter />

    </div>
  );
}

export default SolucoesPage;
