import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'

import Checklist from '../components/main-components/checklist';


const CheckListPage = () => {
  return (
    <>
      <Checklist />
    </>
  );
};

export default CheckListPage;