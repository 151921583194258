import React from 'react';
import Sidebar from '../sidebar'
import '../../App.css';
import '../../css/sidebar.css'
import UpgradeCard from '../UpgradeCard';


function UpgradeComponent() {
  return (
    <div>
      <div className="side">
        <Sidebar />
        <div className='content'>
          <UpgradeCard />
        </div>
      </div>
    </div>
  );
}

export default UpgradeComponent;
