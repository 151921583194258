import React from 'react';

import Sidebar from '../sidebar';
import '../../App.css';
import '../../css/sidebar.css';
import Cardp from '../place';
import subperfil from '../img/photos/subperfil.png'
import IconDropdown from '../dropdown'

function Dash() {
  return (
    <>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
    </head>
      <div className="side">
        <Sidebar />

        <div className='didigod'>
        <div className='topbar'> 
            <h4 className='PR'>Painel de Risco 
              <img className='imgsub' src={subperfil} alt="Logo" />
            </h4> 
              <IconDropdown/> 
          </div>
        <div className='content'>
          
          <Cardp />
        </div>
      </div>
      </div>
    </>
  );
}

export default Dash; // Envolve o componente Dash com o PrivateRoute
