
import Card from 'react-bootstrap/Card';
import '../css/card.css'


function SCard2() {
  return (

    <Card  className='CardMain'>
      
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>

        <Card.Title className='cardtitle'>Por onde começo?</Card.Title>
        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Com o perfil de risco em mãos, você acessa o nosso plano de ação e entende como pode melhorar a sua segurança. Além de scan ilimitado, você conta com atendimento personalizado para melhorar o risco da sua empresa.
        <br/>
        <br/>

        Oferecemos monitoramento contínuo na nossa plataforma, além de descontos exclusivos nos parceiros do nosso marketplace. 
        </Card.Text>



      </Card.Body>
    </Card>
  );
}

export default SCard2;