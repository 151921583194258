import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ReactComponent as MySvg } from './img/photos/Perfil.svg';
import '../css/dash.css'
import Perfil from './img/photos/Perfil.png'
import Exit from './img/photos/Exit.png'
import PerfilModal from './perfil';
import { useNavigate } from 'react-router-dom'; // Import do useNavigate


const IconDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate(); // Inicializa o hook de navegação

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleProfileClick = () => {
    navigate('/perfil'); // Redireciona para a página de perfil}
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} >
      <DropdownToggle caret className='droptoggle'>
        <MySvg />
      </DropdownToggle>
      <DropdownMenu className='dropdownmenu'>
        <div className='ajustedrop'>
        <DropdownItem  onClick={handleProfileClick} className='perfildrop'><img className='imgPerfil' src={Perfil} alt="Logo" /> Perfil </DropdownItem>
        </div>
        <DropdownItem className='textdropdown'><img className='imgSair' src={Exit} alt="Logo" /> Sair</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default IconDropdown;
