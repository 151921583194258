import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../css/dash.css'
import '../css/upgrade.css'




function CopilotCard() {
  return (
    <div>
      <div className='CardS'>
        <div className="d-flex justify-content-around">

        <Card style={{ width: '90%' }} className='Card1Co'>
            <Card.Body>
              <Card.Title className='title1Co'>Cat CISO: seu guia simplificado para a cibersegurança</Card.Title>
              <Card.Text className='text1Co'>
              Conheça o Cat CISO, o guia que descomplica a cibersegurança para você.<br/> 
              Acesse o Cat CISO para entender facilmente as recomendações de segurança e iniciar sua jornada para tornar sua empresa mais segura.
              </Card.Text>

              

              <Button variant="primary" className='buttonco1' href="https://chatgpt.com/g/g-RehUPSe4B-cat-ciso"
                target="_blank"
                rel="noopener noreferrer">Teste agora</Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CopilotCard;