import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import Cfooter from '../Hfooter';
import LoginText from '../textsublogin';



function Sublogin() {
  return (
    <div className='home'>
      <HNavbar className='bgc' />
      <LoginText />
      <Cfooter />
    </div>
  );
}

export default Sublogin;