
import '../css/table.css'
import OffMFA from '../offcanvas/offcanvasmfa';
import OffRSW from '../offcanvas/offcanvasRSW';
import OffPS from '../offcanvas/offcanvasPS';
import OffRF from '../offcanvas/offcanvasRF';
import OffSE from '../offcanvas/offcanvasSE';
import OffCCA from '../offcanvas/offcanvasCCA';
import OffAPS from '../offcanvas/offcanvasAPS';
import OffBackup from '../offcanvas/offcanvasBackup';
import OffTF from '../offcanvas/offcanvasTF';
import OffPRCN from '../offcanvas/offcanvasPRCN';
import OffFW from '../offcanvas/offcanvasFW';
import React, { useState } from 'react';
import pendente from './img/photos/warning.png'
import concluido from './img/photos/Concluido.png'

const TabelaEstatica = () => {

  const [status, setStatus] = useState({
    mfa: true,
    rsw: true,
    ps: true,
    rf: true,
    se: true,
    cca: true,
    aps: true,
    backup: true,
    tf: true,
    prcn: true,
    fw: true
  });

  const toggleStatus = (key, newStatus) => {
    setStatus(prevStatus => ({
      ...prevStatus,
      [key]: newStatus
    }));
  };

  return (
    <table border="0" className='table' id='tablecheck'>
      <thead>
        <tr>
          <th className='tarefa'>Tarefa</th>
          <th>Situação</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='rowtdC'>MFA</td>
          <td><div className='rowtdS'>
              {status.mfa ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div> }
            </div></td>
          <td><OffMFA 
               status={status.mfa} 
               toggleStatus={(newStatus) => toggleStatus('mfa', newStatus)} 
                className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Restrição à acesso de servidores ou websites</td>
          <td><div className='rowtdS'>
              {status.rsw ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffRSW
                 status={status.rsw} 
                 toggleStatus={(newStatus) => toggleStatus('rsw', newStatus)} 
                  className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Proteção a senhas</td>
          <td><div className='rowtdS'>
              {status.ps ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffPS 
                status={status.ps} 
                toggleStatus={(newStatus) => toggleStatus('ps', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Restrição à acesso de funcionários</td>
          <td><div className='rowtdS'>
              {status.rf ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffRF 
                status={status.rf} 
                toggleStatus={(newStatus) => toggleStatus('rf', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Segurança de e-mail</td>
          <td><div className='rowtdS'>
              {status.se ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffSE
               status={status.se} 
               toggleStatus={(newStatus) => toggleStatus('se', newStatus)} 
                className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Criptografia e controle de acesso </td>
          <td><div className='rowtdS'>
              {status.cca ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffCCA
                status={status.cca} 
                toggleStatus={(newStatus) => toggleStatus('cca', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Atualização de patches e softwares</td>
          <td><div className='rowtdS'>
              {status.aps ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffAPS
                status={status.aps} 
                toggleStatus={(newStatus) => toggleStatus('aps', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Backup</td>
          <td><div className='rowtdS'>
              {status.backup ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffBackup
                status={status.backup} 
                toggleStatus={(newStatus) => toggleStatus('backup', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Treinamento de funcionários</td>
          <td><div className='rowtdS'>
              {status.tf ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffTF
                status={status.tf} 
                toggleStatus={(newStatus) => toggleStatus('tf', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Plano de resposta a incidentes e continuidade de negócios</td>
          <td><div className='rowtdS'>
              {status.prcn ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
              
            </div></td>
            <td><OffPRCN 
                status={status.prcn} 
                toggleStatus={(newStatus) => toggleStatus('prcn', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
        <tr>
          <td className='rowtdC'>Firewalls</td>
          <td><div className='rowtdS'>
              {status.fw ? <div className='pendentediv'><img className='Concluido' src={concluido} alt="Logo" /> <h5 className='textpendente'>Concluido</h5> </div> : <div className='pendentediv'><img className='warning' src={pendente} alt="Logo" /> <h5 className='textpendente'>Pendente</h5> </div>}
             
            </div></td>
            <td> <OffFW
                status={status.fw} 
                toggleStatus={(newStatus) => toggleStatus('fw', newStatus)} 
                 className='offcanvas'
              /></td>
        </tr>
      </tbody>
    </table>
  );
};

export default TabelaEstatica;
