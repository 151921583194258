
import '../css/email.css'

function handleButtonClick() {
  window.location.href = '/sublogin';
}

function EmailText() {
  return (
  <div>
    <head>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
    </head>
    <div className='EmailSecMain'>
   <div className='EmailSec'>
    <h1 className='emailtitle'>Cheque seu e-mail para<br /> completar o cadastro.</h1>
    <h4 className='emailsubtitle'>Enviamos um e-mail de verificação para example@gmail.com</h4>
    
    <button className='emailbutton' onClick={handleButtonClick}>Não recebeu o e-mail?</button>

    <h4 className='linkemail'>Já tenho uma conta: <a className='LoginEmailLink' href='/'>Log In</a></h4>
   </div>
   </div>
   </div>
  );
}

export default EmailText;