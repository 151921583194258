import '../css/card.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import dashboardimg from '../components/img/photos/dashhome.png'
import dashhomepng2 from '../components/img/photos/dashhomejpg2.png'
import {
  Label,
  Input,
  FormGroup

} from 'reactstrap';

function handleButtonClick() {
  window.location.href = '/reg';
}



function Text() {
  return (
    <div>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&display=swap" rel="stylesheet"/> 
        <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
      </head>

      <div className='fundo'>
        <div className='Cardtext'>
          <div className='text'>
            <h1 className='h1text'>Quer investir em <br /> Cibersegurança <br /> e não sabe por onde começar ?</h1>
            
            <h1 className='h1text'>Agora você sabe.</h1>

            <h3 className='h3text'>Você no controle: entenda o seu risco e decida como lidar com ele. </h3>
            <div className='emailgroup'>
              <input className='InputHome' placeholder='Endereço de E-mail' ></input>
              <button className='ButtonInputHome' onClick={handleButtonClick}>Cadastre-se</button>
            </div>
            <div className='checkbox'>
              <FormGroup check >
                <Input type="checkbox" className='checkbox' />
                {''}
                <Label check className='checkboxtext'>
                Estou de acordo com os Termos de Serviço e <a className='linkh6' href='/pep'>Política de Privacidade</a> 
                </Label>
              </FormGroup>
            </div>
            <h7 className='h7'>Já tem uma conta? <a className='linkh6' href='/login'>Log-in</a></h7>
          </div >

        </div>

        <div className='vcx'>
          <div className='img'>
            <img className='imgg'  src={dashhomepng2} alt='img'></img>
            <h4 className='h4text'>Uma plataforma intuitiva de gestão de riscos, para tomadores de decisão: entenda como está o risco da sua empresa e como você pode mitigar e transferir esse risco. Acesse agora a versão gratuita da nossa plataforma e dê o primeiro passo para proteger sua empresa.
          </h4>
          </div >
          
        </div>
      </div>
    </div>
  );
}

export default Text;