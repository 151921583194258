import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleRS({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Redirecionamentos de Páginas (Redirect Status)</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>Descrição:</b>
            <p>O termo "Redirect Status" em um scan de cibersegurança refere-se à maneira como o seu site lida com redirecionamentos, que são instruções que dizem ao navegador para levar o visitante de uma URL (endereço de web) para outra.
            </p>
          <br/>
          <b>O que significa "Redirect Status"?</b>
          <br/><br/>
          </div>

          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>O que é um Redirecionamento?</b>  Um redirecionamento ocorre quando alguém tenta acessar uma URL, mas o servidor do site automaticamente leva essa pessoa para uma outra URL. Isso pode acontecer por vários motivos, como:</li>
              <br/>
              <li className='sublista'>o	O site mudou de endereço (por exemplo, de "http://meusite.com" para "https://meusite.com").</li>
              <br/>
              <li className='sublista'>o	A página foi movida para um novo local.</li>
              <br/>
              <li className='sublista'>o	A URL antiga foi substituída por uma nova.</li>
            </ul>
          </div>

          <b>Por que o Redirect Status Pode Ser um Problema?</b>
          <br/><br/>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'> <b>o	Redirecionamentos Inseguros: </b>Se o redirecionamento não for configurado corretamente, pode levar os usuários para uma versão insegura do site (por exemplo, de "https://meusite.com" para "http://meusite.com"). Isso significa que as informações trocadas entre o usuário e o site podem não estar protegidas.</li>
              <br/>
              <li className='lista'><b>o	Redirecionamentos Externos:  </b>Redirecionar visitantes para sites externos pode ser arriscado se esses sites não forem confiáveis, pois pode expor os usuários a sites maliciosos.</li>
              <br/>
              <li className='lista'><b>o	Redirecionamentos Múltiplos:  </b>Vários redirecionamentos em cadeia (um redirecionamento levando a outro e assim por diante) podem causar lentidão na navegação e confundir os mecanismos de busca, o que prejudica a experiência do usuário e o ranking do site.</li>
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Garanta que os redirecionamentos sejam para URLs seguras (HTTPS).</a></li>
              <li className='lista'><a>Simplifique redirecionamentos múltiplos.</a></li>
              <li className='lista'><a>Revise os redirecionamentos para sites externos.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashRS() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleRS key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashRS