import '../css/card3.css'
import TextCardC from './cardC';
import InputC from './inputC';


function ContratoCard() {
  return (
    <div>
      <div className="backgrounds">
        <div className="cardSC">
          <TextCardC />
          <InputC />
        </div>
      </div>

    </div>

  );
}

export default ContratoCard;