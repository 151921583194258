import React from 'react';
import feather from 'feather-icons';
import TesteDb from './teste';
import Register from '../components/main-components/sign';

const RegisterPage = () => {

  feather.replace();

  return (
    <>
      <Register />

    </>
  );
};

export default RegisterPage;