import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import Cfooter from '../Hfooter';
import ContratoCard from '../contratCard';
import Espacamento from '../espacamento';


function ContratPage(){
  return (
    <div>
      <HNavbar className='bgc'  />
      <div className='ColumnContrato'>
        <ContratoCard />
        <Espacamento />
        <Cfooter />
      </div>

    </div>
  );
}

export default ContratPage;
