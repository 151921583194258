import {  Linkedin } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import '../css/card.css'

function Cfooter() {
  return (
    <div className='mainfooter'>
      <div className='footer'>
        <div className='cat'>
          
          <div>
            <h6><a href='/' className='linkfooter'>Sobre Nós</a></h6>
            <h6><a href='/solucoes' className='linkfooter'>Nossa Solução</a></h6>
            <h6><a href='/pep' className='linkfooter'>Politica e Privacidade</a></h6> 
          </div>
        </div>
        <div className='column'>
          
          <h6> <a href='https://api.whatsapp.com/send/?phone=5511976609267&text&type=phone_number&app_absent=0' target='blank' className='linkfooter'>Fale Conosco</a></h6>
          <h6>contato@cateyecyber.com</h6>
          <div className='comboweb'> <a href='https://www.linkedin.com/company/cat-eye-cyber/'><Linkedin  color='black' className='webicon' /></a>  <a href='https://api.whatsapp.com/send/?phone=5511976609267&text&type=phone_number&app_absent=0'><FontAwesomeIcon icon={faWhatsapp}  className="whatsapp-icon" /></a>  </div>
        </div>

      
      </div >
      <div className='caption'>
      <h7> Cateye © Todos os direitos reservados</h7>
      <h7 className='disclaimer'> *Dashboard disponivel apenas na versão desktop*</h7> 
      </div>
    </div>
  );
}

export default Cfooter;