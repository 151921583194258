import React from 'react';
import SignIn from '../components/main-components/login'
import feather from 'feather-icons';
import TesteDb from './teste';

const LoginPage = () => {

  feather.replace();

  return (
    <>
      <SignIn />

    </>
  );
};

export default LoginPage;