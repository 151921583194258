import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/js/AuthContext.js';
import { DashboardAuthProvider } from './components/js/dashAuth.js';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import DashPage from './pages/DashPage';
import Solucoes from './pages/SolucoesPage';
import MarketPage from './pages/MarketPage';
import Contrat from './pages/ContratPage';
import SubEmail from './pages/SubEmail';
import SubLogiPage from './pages/Sublogin';
import MainPolitica from './pages/PEP';
import UpgradePage from './pages/UpgradePage';
import CopilotPage from './pages/CopilotPage';
import ServicesPage from './pages/CateyeServicesPage';
import PrivateRoute from './components/js/PrivateRoute.jsx';
import DashboardPrivateRoute from './components/js/DashPrivate.jsx';
import MarketDashPage from './pages/MarketDashPage.js';
import CheckListPage from './pages/CheckListPage.js';
import RegisterPage from './pages/RegisterPage.js';
import TradeLoginPage from './pages/TrocSenhaPage.js';
import AdminPage from './pages/AdminPage.js';
import PerfilPage from './pages/PerfilPage.js';



function App() {

  return (
    <BrowserRouter>
        <AuthProvider>
        <DashboardAuthProvider>
        <Routes >
          <Route path="/" element={<HomePage />} exact/>
          <Route path="/login" element={<LoginPage />} exact/>
          <Route path="/dashboard" element={<DashboardPrivateRoute><DashPage /></DashboardPrivateRoute>} />
          <Route path="/solucoes" element={<Solucoes />} exact/>
          <Route path="/market" element={<MarketPage />} exact/>
          <Route path="/contrat" element={<Contrat />} exact/>
          <Route path="/subemail" element={<SubEmail />} exact/>
          <Route path="/sublogin" element={<SubLogiPage />} exact/>
          <Route path="/pep" element={<MainPolitica />} exact/>
          <Route path="/upgrade" element={<UpgradePage />} exact/>
          <Route path="/copilot" element={<CopilotPage />} exact/>
          <Route path="/services" element={<ServicesPage />} exact/>
          <Route path="/marketdash" element={<MarketDashPage />} exact/>
          <Route path="/check" element={<CheckListPage />} exact/>
          <Route path="/reg" element={<RegisterPage />} exact/>
          <Route path="/troca" element={<TradeLoginPage />} exact/>
          <Route path="/perfil" element={<PerfilPage />} exact/>
          <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} exact/>
        </Routes>
        </DashboardAuthProvider>
        </AuthProvider>
    </BrowserRouter>
  );

}

export default App;
