
import Card from 'react-bootstrap/Card';
import '../css/card.css'



function SCard3() {
  return (
    <Card className='CardMain'>
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>
        <Card.Title className='cardtitle'>
        E em caso de incidentes? </Card.Title>
        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Não existe bala de prata em cibersegurança. Além de te ajudar a evitar ataques, caso um incidente aconteça a nossa plataforma garante o direito ao reembolso de custos relacionados a um incidente cibernético e assistência 24/7.  
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default SCard3;