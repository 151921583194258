import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover
   
} from 'reactstrap';
import olho from '../components/img/photos/olho.png'
import { ShieldPlus, Info } from 'lucide-react';

function OffCanvasFW({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,

  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2  ;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>FIREWALLS</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
         <p>Imagine que um firewall é como uma parede de proteção que impede a entrada de intrusos na sua casa. Na cibersegurança, o firewall é um software ou dispositivo que controla o acesso à rede da empresa, bloqueando ameaças e acessos não autorizados.</p>
         <p>Ele filtra o que entra e sai da rede da empresa, garantindo que apenas tráfego seguro e permitido possa passar.</p>

         <b>Por isso, é importante rede de visitantes está separada dos dados da empresa:</b> <br/> <br/>

         <p>Pense em um café que oferece Wi-Fi gratuito para os clientes. Esse Wi-Fi não deve ter acesso direto aos sistemas e dados internos da empresa.</p>
         <p>Isso significa que, quando alguém se conecta à rede de visitantes (ou rede de convidados), essa conexão não pode acessar os dados sensíveis ou internos da empresa. É como se fossem duas redes diferentes: uma para os visitantes e outra para os funcionários, sem que uma possa "ver" a outra.</p>

         <p>Em resumo, recomendamos que é importante usar firewalls para proteger a rede da empresa e garantir que qualquer rede oferecida aos visitantes (como Wi-Fi para clientes) não tenha acesso aos dados internos e importantes da empresa. Isso ajuda a manter a segurança e a privacidade das informações da empresa.</p>

         <br />
         <b>Marque as ações implementadas</b>
         <div className='backgroundinputoff'>
         <input
         className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Confirmamos que os firewalls estão em uso e que qualquer rede de visitantes está separada dos dados da empresa.
          <br/>
          <br/>

            <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />	Temos configurações de firewall para tráfego de entrada e saída, com retenção de logs 
           <Info className='InfoButton'
              size={17}
              id="PopoverLegacy"
              type="button"
            >
            </Info>
          <div>
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              <p><b>Tráfego de entrada (inbound) </b>refere-se aos dados que estão entrando na rede da empresa, como emails recebidos ou acessos a sites internos.</p>

              <p><b>Tráfego de saída (outbound) </b>refere-se aos dados que estão saindo da rede da empresa, como emails enviados ou acessos a sites externos.</p>

              <p><b>Configurações de firewall: </b>a empresa configurou o firewall para controlar e filtrar tanto o tráfego que entra quanto o que sai, garantindo que apenas atividades seguras sejam permitidas.</p>

              <p><b>Retenção de logs: </b>o firewall mantém registros (logs) de todas as atividades de entrada e saída. Esses registros são úteis para monitorar a segurança, detectar atividades suspeitas e solucionar problemas se necessário.”</p>

              </PopoverBody>
            </UncontrolledPopover>
            </div>

            </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffFW({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasFW key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffFW;