import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import UpgradeComponent from '../components/main-components/upgradedash';


const UpgradePage = () => {
  return (
    <>
      <UpgradeComponent />
    </>
  );
};

export default UpgradePage;