import { useState,useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import olho from '../components/img/photos/olho.png'

function OffCanvasRF({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>Restrição a acesso de funcionários</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <b>Limite o acesso dos funcionários a informações críticas somente com base na necessidade de saber e no menor privilégio.</b>
          <p>Vamos dividir isso em duas partes para entender melhor:</p>

          <h4 className='subtitulo'>Entenda</h4>
          <p> <b className='boldlist'>1.	Necessidade de saber (need-to-know):</b>   Esse conceito significa que um funcionário deve ter acesso apenas às informações que são estritamente necessárias para realizar seu trabalho. Por exemplo, um funcionário do departamento financeiro precisa ter acesso aos registros financeiros da empresa, mas não necessariamente aos detalhes dos projetos de pesquisa e desenvolvimento.</p>
          <p> <b className='boldlist'>2.	Menor privilégio (least privilege):</b>   Esse princípio diz que qualquer pessoa dentro da organização deve ter o mínimo de privilégios necessário para realizar suas funções. Isso significa não apenas limitar os tipos de informações a que podem acessar, mas também as ações que podem executar nos sistemas da empresa. Por exemplo, se um funcionário precisa usar um software específico, ele deve ter permissão para usá-lo, mas não necessariamente para instalar novos programas.</p>

          <p>Essa recomendação é importante porque ajuda a proteger as informações críticas da empresa contra acesso não autorizado ou vazamento, minimizando os riscos de segurança. Ao aplicar esses princípios, a empresa assegura que as informações sensíveis são acessadas apenas por quem realmente precisa delas e apenas no nível necessário para suas tarefas. Isso reduz a superfície de ataque e aumenta a segurança geral da organização.</p>


          <b>Marque as ações implementadas</b>
          <br/>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          /> Garantimos que os funcionários tenham apenas os privilégios necessários para realizar suas tarefas. Por exemplo: um funcionário do marketing não tem acesso aos mesmos privilégios que um funcionário do TI.

            <br/>
            <br/>

          <input
            className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          /> Configuramos sistemas e permissões para garantir que apenas os funcionários autorizados tenham acesso às informações críticas.

            <br/>
            <br/>

          <input
            className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          /> Utilizamos ferramentas de monitoramento para rastrear quem acessa as informações críticas e quando.

          </div>
          <br/>
          <br/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffRF({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasRF key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus}  />
      ))}
    </>
  );
}

export default OffRF;