import { openDB } from 'idb';

const DB_NAME = 'my-database';
const DB_VERSION = 1;
const STORE_NAME = 'my-store';

async function initializeDatabase() {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      // Aqui você pode criar objetos de armazenamento ou fazer outras operações de atualização
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
        // Se desejar, você pode definir índices para pesquisas rápidas
        store.createIndex('name', 'name', { unique: false });
      }
    },
  });
  return db;
}


export async function openDatabase() {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME);
    },
  });
  console.log('Banco de dados aberto com sucesso:', db);
  return db;
}


export async function saveData(data) {
  const db = await openDatabase();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.put(data);
}

export async function getData() {
  const db = await openDatabase();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  return await store.getAll();
}

// Chamada da função para inicializar o banco de dados
initializeDatabase()
  .then(db => {
    console.log('Banco de dados inicializado:', db);
  })
  .catch(error => {
    console.error('Erro ao inicializar o banco de dados:', error);
  });
