
import Card from 'react-bootstrap/Card';
import '../css/dash.css'
import '../css/upgrade.css'
import '../css/info.css'
import { Info } from 'lucide-react';





function InfoCard() {
  return (
    <div>

      <div className='espaçamentoinfo'>
        
      </div>

      <div className='CardS'>
        <div className="d-flex justify-content-around">
        <div className='cardresponsive'>
        <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body>
              <div className='rowinfo'>
              <Card.Title className='titleinfo'><Info size={20}/></Card.Title>
              <Card.Text className='text1up'>
              Ideal para você começar a conversa sobre investimento em cibersegurança na sua empresa. Na versão grátis você ganha acesso a um scan de vulnerabilidades e ao nosso checklist de segurança, que vem com uma linguagem pensada em profissionais que não tem expertise em cibersegurança.
              </Card.Text>
              </div>
            </Card.Body>
          </Card>


          <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body>
              <div className='rowinfo'>
              <Card.Title className='titleinfo'><Info size={20}/></Card.Title>
              <Card.Text className='text1up'>
              O nome do nosso plano intermediário já diz muito: iniciante. Esse plano é para empresas que estão começando a traçar o seu plano de ação de investimento em cibersegurança. Além de scan ilimitado, neste plano você conta com um plano de ação personalizado para melhorar o risco da sua empresa.
              </Card.Text>
              </div>
            </Card.Body>
          </Card>
         

          <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body>
              <div className='rowinfo'>
              <Card.Title className='titleinfo'><Info size={20}/></Card.Title>
              <Card.Text className='text1up'>
              O plano para a empresa que já entendeu que um ataque cibernético não é uma questão de se, mas de quando. O nosso plano Proteção 360 conta com assistência 24/7 e reembolso em caso de incidentes, por meio do seguro cibernético.
              </Card.Text>
              </div>
            </Card.Body>
          </Card>

          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;