import React from 'react';
import '../css/app.css'
import Marketplace from '../components/main-components/marketplace';


const MarketPage = () => {
  return (
    <>
      <Marketplace />
    </>
  );
};

export default MarketPage;