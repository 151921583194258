import React, { useEffect }  from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import GroupCards from '../group-cards';
import Cfooter from '../Hfooter';
import Text from '../section';

function Home() {

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-WFCJWTFLFZ';
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WFCJWTFLFZ');
    `;
    document.head.appendChild(script2);

    return () => {
      // Limpeza dos scripts ao desmontar o componente
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <div>
    <head>
      <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
    </head>
    <div className='home'>
      <HNavbar className='bgc' />
      <Text />
      <GroupCards />
      <Cfooter />

    </div>
    </div>
  );
}

export default Home;
