import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import MarketDash from '../components/main-components/marketdash';



const MarketDashPage = () => {
  return (
    <>
      <MarketDash />
    </>
  );
};

export default MarketDashPage;