import Card from 'react-bootstrap/Card';
import '../css/card3.css'



function TextCardC() {
  return (
    <Card className='t4'>
      <div className='titlecard'>
        <Card.Body>
          <div className='title'>
            <Card.Title><h1 className='title'>Fale Conosco</h1></Card.Title>
          </div>
          <Card.Subtitle className="mb-2 text-muted" id='subtitle'>Dúvidas, sugestões? Entre em contato com nossa equipe.</Card.Subtitle>
        </Card.Body>
      </div>
    </Card>
  );
}

export default TextCardC;