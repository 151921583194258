
import Card from 'react-bootstrap/Card';
import '../css/card.css'
import card3 from '../components/img/photos/card3.png'


function HCard3() {
  return (

    <Card className='CardMain'>
      
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>
        <img alt='img' width={30} height={30} className='imgcard2' src={card3} ></img>
        <Card.Title className='cardtitleamarelo'><p className='amarelo2'>Transferência</p></Card.Title>

        <Card.Title className='subtitlepreto'>Proteja seu patrimônio e conte com assistência 24/7</Card.Title>
        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Não existe bala de prata em cibersegurança. Caso o pior aconteça, nossa plataforma garante o reembolso de custos relacionados a um incidente cibernético e assistência 24/7.
        </Card.Text>

    

      </Card.Body>
    </Card>
  );
}

export default HCard3;