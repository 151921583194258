import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import Cfooter from '../Hfooter';
import PoliticCard from '../politica-card';
import GroupCardP from '../group-politic';

function ComponentPolitica() {
  return (
    <div>
      <HNavbar className='bgc'  />
      <PoliticCard />
      <div className='groupsolution'>
        <GroupCardP className='boxshadow' />
      </div>
      <Cfooter />

    </div>
  );
}

export default ComponentPolitica;
