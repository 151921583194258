import React, { useState, useEffect } from 'react';

function TesteDb() {
  const [dados, setDados] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('http://127.0.0.1:5000/dados');
        if (!response.ok) {
          throw new Error('Erro ao obter dados');
        }
        const data = await response.json();
        setDados(data);
      } catch (error) {
        console.error('Erro:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>Dados da Tabela</h1>
      <ul>
        {dados.map((row, index ) => (
          <li key={index}>{row}</li> // Supondo que 'nome' e 'email' são colunas da tabela 'usuarios'
        ))}
      </ul>
    </div>
  );
}

export default TesteDb;
