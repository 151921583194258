import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import Cfooter from '../Hfooter';
import Marketcard from '../market-card';
import GroupCards from '../group-cards';
import CardList from '../SectionCardMarket';

function Marketplace() {
  return (
    <div>
      <HNavbar className='bgc'  />
      <Marketcard />
      <div className='groupsolution'>
        <GroupCards className='boxshadow' />
        <CardList />
      </div>
      <Cfooter />

    </div>
  );
}

export default Marketplace;
