import Card from 'react-bootstrap/Card';
import '../css/card3.css'

function MarketText() {
  return (
    <Card className='t4'>
      <div className='titlecard'>
        <Card.Body>
          <div className='title'>
            <Card.Title><h1>MarketPlace</h1></Card.Title>
          </div>
          <Card.Subtitle className="mb-2 text-muted" id='subtitle'>Nesta pagina você podera observar as soluções da Cateye e como elas podem ser úteis para você tendo em vista que esse texto é de teste e só precisava ser grande</Card.Subtitle>
        </Card.Body>
      </div>
    </Card>
  );
}

export default MarketText;