import React from 'react';
import Sidebar from '../sidebar'
import '../../App.css';
import '../../css/sidebar.css'

import UpgradeCard from '../UpgradeCard';
import InfoCard from '../infoCard';



function CatServicesComponent() {
  return (
    <div>
      <div className="side">
        <Sidebar />
        <div className='content'>
          <UpgradeCard/>
          <InfoCard  />

        </div>
      </div>
    </div>
  );
}

export default CatServicesComponent;