import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover
   
} from 'reactstrap';
import olho from '../components/img/photos/olho.png'
import { ShieldPlus, Info } from 'lucide-react';
import '../css/offcanvas.css'

function OffCanvasRSW({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>Restrição à acesso de servidores ou websites</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p className='cabeçalho'><b>Restringiro acesso de servidores ou websites que sua empresa utiliza</b> significa limitar quem e o que pode acessar as tecnologias que estão expostas à internet. Por exemplo, servidores ou websites que sua empresa utiliza e que podem ser acessados de fora da rede interna da empresa.</p>

          <h4 className='subtitulo'>Entenda</h4>
          <p><b className='boldlist'>•	  Por que é importante?</b>  Restringir esse acesso ajuda a proteger essas tecnologias contra ataques externos, como hackers que tentam invadir sistemas para roubar informações ou causar danos.</p>

          <p><b className='boldlist'>•	  Firewall</b> É como um filtro que verifica tudo que entra e sai da rede de uma empresa pela internet, bloqueando acessos perigosos ou não autorizados. Pensa num firewall como um segurança na porta de uma boate, decidindo quem pode entrar baseado em critérios específicos.</p>

          <p><b className='boldlist'>•	  VPN (Virtual Private Network)</b>  VPN é uma tecnologia que cria um túnel seguro através da internet, permitindo que você acesse a rede da sua empresa de forma segura, mesmo estando longe fisicamente. É como usar um caminho secreto e protegido para acessar informações importantes sem que outras pessoas possam ver ou interferir.</p>

          <p><b className='boldlist'> •	  Zero Trust Network Access</b> A abordagem de Zero Trust é um conceito de segurança baseado na ideia de que não se deve confiar em ninguém automaticamente, nem mesmo dentro da própria rede da empresa. Todo acesso deve ser verificado rigorosamente antes de permitir a entrada aos sistemas. Imagine que, em vez de todo mundo na empresa ter a chave da sala do cofre, cada pessoa precisa provar quem é e por que precisa acessar o cofre cada vez que desejar entrar.</p>

          <p>Cada uma dessas medidas serve para reforçar a segurança e garantir que apenas pessoas autorizadas e de maneira segura tenham acesso a recursos críticos da tecnologia da informação.</p>
          
          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
          className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          /> •	Todas as tecnologias e softwares voltados para a internet (como ativos, painéis administrativos, serviços, aplicações, portas, etc.) devem ser configurados para incluir apenas os privilégios mínimos necessários e restringir o acesso pela internet 
          <Info className='InfoButton'
          size={17}
          id="PopoverLegacy"
          type="button"
        >
        </Info>
          <div>
            
          
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              O que isso significa? Essa recomendação indica que qualquer elemento da sua infraestrutura tecnológica que possa ser acessado de fora da rede interna da empresa deve ser configurado para operar com o mínimo de permissões possíveis. Além disso, o acesso a esses elementos por meio da internet deve ser bastante limitado para evitar exposições desnecessárias. <br/> <br/>
              Por que é importante? Limitar os privilégios e o acesso ajuda a garantir que, mesmo que um atacante consiga acessar algum desses componentes, ele não conseguirá fazer muitos danos ou acessar informações sensíveis, pois as permissões estarão restritas ao essencial para a operação daquele componente.]
              </PopoverBody>
            </UncontrolledPopover>
            </div>

          <br />
          <input
          className='testeinput'
            type="checkbox"
            name="checkbox2"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          /> •	Todo o software de gestão remota deve estar protegido atrás de uma VPN ou firewall  <Info className='InfoButton'
          size={17}
          id="PopoverLegacy2"
          type="button"
        >
        </Info>
          <div>
            
          
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy2"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              O que isso significa? Softwares que permitem o gerenciamento de sistemas à distância devem ser acessados somente através de redes seguras, estabelecidas por VPNs (Redes Privadas Virtuais) ou protegidas por firewalls. Essas tecnologias ajudam a criar uma barreira que verifica e controla o tráfego de entrada e saída, garantindo que apenas conexões seguras e autorizadas sejam permitidas <br/> <br/>
              Por que é importante? A gestão remota pode ser uma porta de entrada para ameaças se não for bem protegida, pois permite controlar sistemas de qualquer lugar. Ao colocar esses softwares atrás de VPNs ou firewalls, reduz-se significativamente o risco de acessos indevidos.]
              </PopoverBody>
            </UncontrolledPopover>
            </div><br/>
          <br />
          <input
          className='testeinput'
            type="checkbox"
            name="checkbox3"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
          /> <Info className='InfoButton'
          size={17}
          id="PopoverLegacy3"
          type="button"
        >
        </Info> O acesso de administrador de domínio é controlado e medidas de segurança são implementadas na administração da rede de TI
          <div>
            
          
            <UncontrolledPopover
              placement="top"
              target="PopoverLegacy3"
              trigger="legacy"
            >
              <PopoverHeader>
              O que isso significa?
              </PopoverHeader>
              <PopoverBody>
              O que isso significa? Acesso de administrador de domínio, que confere controle total sobre a rede e seus recursos, deve ser fortemente regulado e protegido com medidas de segurança rigorosas. Isso inclui a implementação de políticas de segurança que determinam como, quando e por quem esse acesso pode ser utilizado. <br/> <br/>
              Por que é importante? Os administradores de domínio têm capacidades extensivas para alterar configurações e acessar dados importantes. Controlar cuidadosamente esse acesso é fundamental para prevenir abusos e minimizar danos em caso de ataques cibernéticos, assegurando que apenas pessoal qualificado e autorizado possa fazer mudanças significativas na rede.]
              </PopoverBody>
            </UncontrolledPopover>
            </div>
            </div>
            <br/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffRSW({ status, toggleStatus }) {
  return (
    <>
      {['end'].map((placement, idx) => (
        <OffCanvasRSW
        key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus}
        />
      ))}
    </>
  );
}

export default OffRSW;