import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleRP({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Privacidade do Registro do Site (Registration Public)</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>O que significa "Registration Public"?</b>
            <p>Quando você registra um domínio (por exemplo, "meusite.com"), você fornece informações pessoais como nome, endereço, e-mail e número de telefone. 
            <br/><br/>
            Essas informações são armazenadas em um banco de dados público chamado WHOIS, que qualquer pessoa pode acessar.
          
            </p>
          </div>
          
          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Informações Públicas de Registro:  </b>Se o "Registration Public" estiver ativado, significa que qualquer pessoa pode acessar as informações de quem registrou o domínio através de uma busca WHOIS. Isso inclui nome, endereço, e-mail e telefone do proprietário do domínio.</li>
              <br/>
              <li className=''><b>2.	Riscos de Segurança e Privacidade:  </b>Quando as informações de registro do domínio são públicas, você fica exposto a possíveis problemas, como:
              <br/><br/>
              <ul>
              <li className='sublista'><b>Spam: </b> Seu e-mail pode ser capturado por robôs que enviam spam ou e-mails maliciosos.</li><br/>
              <li className='sublista'><b>Phishing: </b> Alguém pode usar suas informações para tentar enganá-lo com golpes, se passando por uma empresa ou pessoa de confiança.</li><br/>
              <li className='sublista'><b>Roubo de Identidade: </b> Seus dados podem ser usados por pessoas mal-intencionadas para fraudes ou outras atividades ilegais.</li>

              </ul>
              </li>
              <br/>
              <li className=''><b>3.	Proteção de Privacidade:  </b> Muitos registradores de domínios oferecem um serviço chamado "Privacy Protection" ou "WHOIS Privacy", que oculta essas informações públicas, substituindo-as por dados genéricos ou do próprio registrador. Isso protege sua privacidade e reduz os riscos mencionados acima.</li>
            </ul>
          </div>

          <b>Em resumo:</b>
          <p>"Registration Public" indica que as informações de registro do seu domínio estão públicas e acessíveis a qualquer pessoa. Isso pode representar riscos à sua privacidade e segurança, tornando importante considerar proteger esses dados.</p>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Ative a proteção de privacidade para ocultar suas informações.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashRP() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleRP key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashRP