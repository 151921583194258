import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import '../css/perfil.css';
import Cookies from 'js-cookie';

function OffCanvasaltEmp({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');  // Estado para armazenar o novo nome da empresa
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Função para lidar com a alteração do nome da empresa
  const handleCompanyNameChange = (e) => {
    setNewCompanyName(e.target.value);
  };

  // Função para enviar o novo nome da empresa ao backend
  const handleSubmit = async () => {
    const accessToken = Cookies.get('access_token');  // Recupera o JWT do cookie

    try {
      const response = await fetch('https://api2.cateyecyber.com/update_company_name', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`  // Adiciona o token JWT ao cabeçalho
        },
        body: JSON.stringify({ new_company_name: newCompanyName })
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Nome da empresa atualizado com sucesso!');
        setError(null);
        setShow(false);  // Fecha o offcanvas após sucesso
      } else {
        setError(data.error || 'Erro ao atualizar o nome da empresa.');
        setSuccessMessage('');
      }
    } catch (error) {
      setError('Erro de rede ao tentar atualizar o nome da empresa.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow} className="me-2" id="button-addon2">
        Alterar
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props} placement='top'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Altere o Nome da Empresa:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='altbody'>
          Faça a Alteração do Nome da Empresa Abaixo:
        
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Novo Nome da Empresa:</InputGroup.Text>
            <Form.Control
              placeholder="Insira seu Novo Nome da Empresa aqui!"
              aria-label="Empresa"
              aria-describedby="basic-addon1"
              value={newCompanyName}  // Valor controlado pelo estado
              onChange={handleCompanyNameChange}  // Atualiza o valor do estado com o novo nome da empresa
            />
          </InputGroup>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <div className='confirmbutton'>
            <Button variant="success" onClick={handleSubmit}>
              Confirmar Alteração
            </Button>
          </div>
          
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasaltEmp;
