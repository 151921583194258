import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleVT({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Verificação de Segurança (VirusTotal) – VT Message</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>Descrição:</b>
            <p>"VT Message" refere-se a um alerta que surge quando um arquivo ou URL do seu site foi analisado no VirusTotal, uma ferramenta que verifica a presença de vírus, malware e outras ameaças. Um alerta que indica que algum arquivo ou URL do seu site foi verificado no VirusTotal, e o scan encontrou algo que merece atenção. 
            <br/><br/>
            Dependendo do resultado, isso pode indicar a presença de malware, um falso positivo ou simplesmente uma necessidade de análise mais aprofundada.
            </p>
            <br/>
            <b>Por que isso é importante?</b>
            <p>Se o VirusTotal identificou algum problema, isso pode significar que seu site está hospedando um arquivo ou link potencialmente perigoso, que pode afetar os visitantes do site.</p>
          </div>
        <br/>
        <b>O que significa "VT Message"?</b>
        <br/><br/>
          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Verificação de Arquivos e URLs no VirusTotal:  </b> Quando você realiza um scan de segurança, o sistema pode verificar se algum arquivo ou URL do seu site já foi enviado ao VirusTotal e qual foi o resultado dessa análise. Um "VT Message" seria um alerta ou uma mensagem relacionada a essa verificação.</li>
              <br/>
              <li className=''><b>2.	Resultados Positivos:  </b> Se o "VT Message" indicar que algum arquivo ou URL do seu site foi identificado como potencialmente malicioso pelo VirusTotal, isso pode significar que ele foi marcado como uma ameaça por uma ou mais ferramentas de detecção. Isso é algo que deve ser investigado com urgência.</li>
              <br/>
              <li className=''><b>3.	Falso Positivo:  </b> Em alguns casos, o "VT Message" pode indicar um "falso positivo", ou seja, um arquivo ou URL que foi marcado como perigoso, mas que na verdade é inofensivo. Isso pode ocorrer devido a características específicas do arquivo ou do código que são mal interpretadas como maliciosas por algumas ferramentas.</li>
              <br/>
              <li className=''><b>4.	Uso de VirusTotal para Validação:   </b> Muitas plataformas de segurança usam o VirusTotal como uma segunda camada de validação para garantir que os arquivos ou URLs associados ao seu site estejam livres de ameaças conhecidas.</li>
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Remova ou corrija qualquer arquivo ou URL marcado como perigoso.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashVT() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleVT key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashVT