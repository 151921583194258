import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import '../css/perfil.css';
import Cookies from 'js-cookie';

function OffCanvasalt({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [newUsername, setNewUsername] = useState('');  // Estado para armazenar o novo username
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Função para lidar com a alteração do username
  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  // Função para enviar o novo username ao backend
  const handleSubmit = async () => {
    const accessToken = Cookies.get('access_token');  // Recupera o JWT do cookie

    try {
      const response = await fetch('https://api2.cateyecyber.com/update_username', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`  // Adiciona o token JWT ao cabeçalho
        },
        body: JSON.stringify({ new_username: newUsername })
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Nome de usuário atualizado com sucesso!');
        setError(null);
        setShow(false);  // Fecha o offcanvas após sucesso
      } else {
        setError(data.error || 'Erro ao atualizar o nome de usuário.');
        setSuccessMessage('');
      }
    } catch (error) {
      setError('Erro de rede ao tentar atualizar o nome de usuário.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShow} className="me-2" id="button-addon2">
        Alterar
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props} placement='top'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Altere seu Username</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='altbody'>
          Faça a Alteração do seu Username Abaixo:

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Novo Username:</InputGroup.Text>
            <Form.Control
              placeholder="Insira seu Novo Username aqui!"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={newUsername}
              onChange={handleUsernameChange}  // Atualiza o valor do estado com o novo username
            />
          </InputGroup>

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <div className='confirmbutton'>
            <Button variant="success" onClick={handleSubmit}>
              Confirmar Alteração
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasalt;
