import React from 'react';
import Dash from '../components/main-components/dash';
import '../css/sidebar.css'
import '../css/app.css'


const DashPage = () => {
  console.log("cheguei");
  return (
    <>
      <Dash />
    </>
  );
};

export default DashPage;