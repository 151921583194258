import React from 'react';
import '../css/app.css'
import ContratPage from '../components/main-components/contrat';


const Contrat = () => {
  return (
    <>
      <ContratPage />
    </>
  );
};

export default Contrat;