import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../css/dash.css'
import '../css/upgrade.css'
import {  ShieldCheck, ScanEye,Plus, CircleCheckBig, CircleDollarSign,Umbrella, Star, Handshake } from 'lucide-react';
import { useNavigate } from 'react-router-dom';




function UpgradeCard() {

  let Navigate = useNavigate();

  const handleClick = () => {
    Navigate('/contrat');
  };


  return (
    <div>
      <div className='CardS'>
        <div className="d-flex justify-content-around">
        <div className='cardresponsive'>
        <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body className='cardbody'>
              
              <Card.Title className='title1up'>Básico</Card.Title>
              <p className='barrinhaup'>.</p>
              <Card.Title className='title1up'>Gratis</Card.Title>
              <Card.Text className='text1up'>
              Nosso plano gratuito para você que quer começar a traçar um plano de ação para a segurança cibernética da sua empresa.  
              </Card.Text>
              <div className='lista1up'>
              <ul>
              <li className='item1L1Up'> <ScanEye color='grey' size={18} className='iconup'/> Scan de vulnerabilidades anual</li>
              <li className='item1L1Up'> <CircleCheckBig color='grey' size={18} className='iconup' /> Checklist de segurança</li>
              <li className='item1L1Up'> <ShieldCheck color='grey' size={18} className='iconup' /> Relatório de segurança</li>

              </ul>

              </div>
              <div className='kitbutton'>
              <Button variant="primary" className='buttonup1' href="https://api.whatsapp.com/send/?phone=5511976609267&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer">Plano atual</Button>
              

              <div className='lista3up'>
              <ul >
              <li className='item3L1Up'> <Plus color='black' size={18} className='iconup2'/><a className='L3' href='/contrat'>Saiba mais</a></li>
              </ul>
              </div>
              </div>
            </Card.Body>
          </Card>


          <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body className='cardbody'>
              
              <Card.Title className='title1up'>Iniciante</Card.Title>
              <p className='barrinhaup2'>.</p>
              <Card.Title className='title1up'>R$ 600 / Mês</Card.Title>
              <Card.Text className='text1up'>
              Monitore seus riscos continuamente e implemente ações para melhorar o seu risco.
              </Card.Text>
              <div className='lista1up'>
              <ul>
              <li className='item1L1Up'> <ScanEye color='grey' size={18} className='iconup' /> Scan de vulnerabilidades ilimitado</li>
              <li className='item1L1Up'> <CircleDollarSign  color='grey' size={18} className='iconup' /> Três sessões de consultoria incluídas</li>
              <li className='item1L1Up'> <ShieldCheck color='grey' size={18} className='iconup' /> Plano de ação personalizado</li>

              </ul>

              </div>
              <div className='kitbutton'>
              <Button variant="primary" className='buttonup3' href="https://api.whatsapp.com/send/?phone=5511976609267&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer">Compre o plano</Button>
              

              <div className='lista3up'>
              <ul >
              <li className='item3L1Up'> <Plus color='black' size={18} className='iconup2'/><a className='L3' href='/contrat'>Saiba mais</a></li>
              </ul>
              </div>
              </div>
            </Card.Body>
          </Card>
         

                  <Card style={{ width: '30%' }} className='Card1Up'>
            <Card.Body className='cardbody'>
              
              <Card.Title className='title1up'>Premium</Card.Title>
              <p className='barrinhaup3'>.</p>
              <Card.Title className='title1up'>Proteção 360</Card.Title>
              <Card.Text className='text1up'>
              Monitore seus riscos, evite ataques e conte com cobertura que indeniza o prejuízo financeiro da sua empresa em caso de ataques cibernéticos.
              </Card.Text>
              <div className='lista1up'>
              <ul>
              <li className='item1L1Up'> <Umbrella  color='grey' size={18} className='iconup' /> Seguro cibernético: reembolso de despesas em caso de ataque</li>
              <li className='item1L1Up'> <Star  color='grey' size={18} className='iconup' /> Scan Ilimitado</li>
              <li className='item1L1Up'> <Handshake color='grey' size={18} className='iconup' /> Assistência 24/7</li>

              </ul>

              </div>
              <div className='kitbutton'>
              <Button variant="primary"  className='buttonup2' href="https://api.whatsapp.com/send/?phone=5511976609267&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer">Obtenha uma cotação</Button>
              

              <div className='lista3up'>
              <ul >
              <li className='item3L1Up'> <Plus color='black' size={18} className='iconup2'/><a className='L3' href='/contrat'>Saiba mais</a></li>
              </ul>
              </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeCard;