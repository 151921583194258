import Card from 'react-bootstrap/Card';
import '../css/card3.css'

function TextCardP() {
  return (
    <Card className='t6'>
      <div className='titlecard'>
        <Card.Body>
          <div className='title'>
            <Card.Title><h1 className='title'>Politica e Privacidade</h1></Card.Title>
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

export default TextCardP;