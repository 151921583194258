import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import '../../App.css';
import Cookies from 'js-cookie';
import '../../css/login2.css';
import cat from '../img/photos/cat.png';
import PL from '../img/photos/PerfilLogin.png';

function TradeLogin({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  
  const handleClick = () => {
    setTimeout(() => {
      navigate('/login');
    }, 3000); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api2.cateyecyber.com/trocaacesso', 
        { email }, // Payload com o email
        {
          withCredentials: true, // Permite o envio de cookies e credenciais
          headers: {
            'Content-Type': 'application/json', // Especifica o tipo de conteúdo
          },
        }
      );
    
      if (response.status === 200) {
        setMessage('Nova senha enviada com sucesso para o seu e-mail');
        handleClick(); // Função que lida com o sucesso
        setError('');  // Limpa o erro, se houver
      } else {
        setError('Erro ao enviar a nova senha. Verifique o e-mail.');
        setMessage(''); // Limpa a mensagem de sucesso, se houver
      }
    } catch (error) {
      console.error('Erro ao solicitar nova senha:', error);
      setError('Erro ao solicitar nova senha. Por favor, tente novamente.');
      setMessage(''); // Limpa a mensagem de sucesso
    }
  };

  return (
    <div className='blogin'>
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">

              <div>
                <div className="card" id='logincard'>
                <img className='cat' src={cat} alt="Logo" />
                  <div className="card-body" id='cardbody'>

                    <div className='logincard'>
                      <h2 className='logintext'>Troca de Senha</h2>
                      <div className='bar'></div>
                    </div>
                  <div className='CardInput3'>

                    <div className="m-sm-3">
                    <h5 className='subtext'>Insira o Email para re-envio da senha</h5>
                      <form onSubmit={handleSubmit} className='formcss'>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <div className='comboform'>
                          <img className='PL' src={PL} alt="Logo" />
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                            id='formemail'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          </div>
                        </div>

                        <div className="d-grid gap-2 mt-3" id='buttonLogin'>
                          <button type="submit" className="btn btn-lg btn-primary" id='btn-submit' >Enviar</button>
                        </div>
                        
                        {error && <div className="text-danger mt-2">{error}</div>}
                        {message && <div className="text-success mt-2">{message}</div>}

                      </form>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TradeLogin;
