import React from 'react';
import '../css/app.css'
import ComponentPolitica from '../components/main-components/politica';


const MainPolitica = () => {
  return (
    <>
      <ComponentPolitica />
    </>
  );
};

export default MainPolitica;