import React from 'react';
import '../css/market.css'

const CardMarket = ({ title, content, imageUrl }) => {
  return (
    <div className="cardlist">
      <img className='marketcard' src={imageUrl} alt={title} />
      <h2 className='titlemarket'>{title}</h2>
      <p className='contentmarked'>{content}</p>
    </div>
  );
};

export default CardMarket;