
import '../css/login.css'

function LoginText() {
  return (
  <div className='met'>
  <div className='metade'>
    <head>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
    </head>
    <div className='loginSecMain'>
   <div className='loginSec'>
    <h1 className='logintitle'> Escolha sua senha.</h1>
    <h4 className='loginsubtitle'>Dica: Evite senhas padrões ou com repetições ex: 123</h4>

    <div className="mb-3">
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Senha"
                         // value={password}
                          //onChange={(e) => setPassword(e.target.value)}
                        />
    </div>

    <div className="mb-3">
                        
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Confirme a Senha"
                          // value={password}
                          //onChange={(e) => setPassword(e.target.value)}
                        />
    </div>
    
    <button className='loginbutton' >Confirme a senha</button>


    <h4 className='linkemail'>Já possui uma conta ? <a className='SubLink' href='/'>Log In</a></h4>

    <div className='caption-'>
        <h7> © 2024 cateye Inc</h7>
      </div>
   </div>
   </div>
   </div>
   </div>
  );
}

export default LoginText;