import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import CopilotComponent from '../components/main-components/copilotcomponent';


const CopilotPage = () => {
  return (
    <>
      <CopilotComponent />
    </>
  );
};

export default CopilotPage;