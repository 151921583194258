import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleDES({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <img className='olho'onClick={handleShow} src={olho} alt="Logo" />
      
      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
           <h5 className='subtitledash'>Validade do Nome do Site (Domínio) – Domain Expiry Status</h5>
          
          <div className='Blocodash'>
          <div className='subblocdash'>
            <b className='titledash'>Gravidade</b>
            <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Ativos</b>
            <p className='textdash'>1</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Primeira Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          <div className='subblocdash'>
            <b className='titledash' >Última Detecção</b>
            <p className='textdash'>17/07/24</p>
          </div>

          </div>

          <div className='textdescricao'>
            <b>Descrição:</b>
            <p>O termo "Domain Expiry Status" em um scan de cibersegurança refere-se à data de validade do seu nome de domínio, que é o endereço do seu site na internet (por exemplo, www.meusite.com).
            </p>
          </div>

          <b>O que significa "Domain Expiry Status"?</b>
          <br/><br/>
          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Validade do Domínio: </b> Todo nome de domínio tem uma data de validade, que é o período pelo qual você tem o direito de usá-lo. Essa data é definida quando você registra ou renova o domínio através de uma empresa de registro de domínios (como GoDaddy, Registro.br, etc.).</li>
              <br/>
              <li className=''><b>2.	Riscos ao Expirar: </b> Se o domínio expirar, o seu site pode ficar inacessível, e você corre o risco de perder o direito de usá-lo. Isso significa que outras pessoas ou empresas podem registrá-lo e usar o nome que você costumava usar, o que pode causar grandes problemas, como perda de tráfego e até danos à reputação da sua empresa.</li>
              <br/>
              <li className=''><b>3.	Impacto na Segurança:  </b> Um domínio expirado pode ser registrado por outra pessoa, que pode usar o antigo nome do seu site para criar uma página maliciosa. Visitantes desavisados podem acessar essa nova página pensando que é o seu site original, o que pode levar a fraudes ou roubo de informações.</li>
            </ul>
          </div>
          <b>Em resumo:</b>
          <p>"Domain Expiry Status" é um alerta que informa que a data de validade do seu nome de domínio está se aproximando ou já expirou. Isso é crítico porque, se o domínio expirar, o seu site pode ficar fora do ar, e você pode perder o controle sobre ele.</p>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Renove o domínio antes que ele expire.</a></li>
              <li className='lista'><a>Ative a renovação automática.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDefaultDES() {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasExampleDES key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDefaultDES