import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';



import olho from '../components/img/photos/olho.png'

function OffCanvasPRCN({ name, status, toggleStatus, ...props }) {
  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,

  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1 ;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>PLANO DE RESPOSTA A INCIDENTES E CONTINUIDADE DE NEGÓCIOS</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
          <p>Imagine que sua empresa é uma casa. Às vezes, podem acontecer imprevistos, como um incêndio, uma inundação ou um roubo. Para estar preparado para esses eventos, você precisa de um plano de ação. No mundo da cibersegurança, a ideia é semelhante.</p>

          <p><b>Plano de Resposta a Incidentes: </b>Este é um plano que sua empresa deve ter para saber o que fazer se algo der errado na área de tecnologia e segurança. Por exemplo, se um hacker invadir o sistema ou se um vírus infectar os computadores. O plano de resposta a incidentes vai dizer:</p>
          <p><b className='boldlist'>1.	Quem</b> deve ser informado primeiro (como o chefe de segurança ou o gerente de TI).</p>
          <p><b className='boldlist'>2.	O que</b> deve ser feito imediatamente (como desligar certos sistemas ou isolar o problema).</p>
          <p><b className='boldlist'>3.	Como</b> investigar o problema (para entender o que aconteceu e como evitar que aconteça de novo).</p>
          <p><b className='boldlist'>4.	Como</b> comunicar aos clientes e parceiros, se necessário (para que todos saibam o que está acontecendo e que medidas estão sendo tomadas).</p>
          <br/>
          <p><b className='boldlist'>Plano de Continuidade de Negócios:</b> Este é um plano para garantir que sua empresa continue funcionando mesmo durante e após uma crise. É como ter um plano B. O plano de continuidade de negócios vai dizer:</p>
          <p><b className='boldlist'>1.	Como</b> manter operações críticas funcionando (por exemplo, garantindo que sistemas importantes tenham backups).</p>
          <p><b className='boldlist'>2.	Como</b> e onde trabalhar se o escritório principal não puder ser usado (como ter locais alternativos de trabalho ou permitir trabalho remoto).</p>
          <p><b className='boldlist'>3.	Como</b> recuperar e restaurar dados e sistemas após o incidente.</p>
          <br/>
          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
            className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />	Nossa empresa possui um plano de resposta a incidentes e continuidade de negócios.
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffPRCN({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasPRCN key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} />
      ))}
    </>
  );
}

export default OffPRCN;