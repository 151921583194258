
import Card from 'react-bootstrap/Card';
import '../css/card.css'


function SCard() {
  return (

    <Card className='CardMain'>
      
      <Card.Body style={{ paddingLeft: '1.5rem' }} className='cardbody'>
   
        <Card.Title className='cardtitle'>Como está meu risco?</Card.Title>

        <div className='barrinha'></div>
        <Card.Text className='cardtext'>
        Entenda seu risco (de forma gratuita) com nosso scan de vulnerabilidades, com uma linguagem simples e didática, destinada a gestores que não querem mais ignorar o risco cibernético.
        <br />
        <br />
        Mostramos como você pode melhorar o seu risco e quais são os passos para implementar ferramentas e estratégias de cibersegurança na sua empresa.
        </Card.Text>



      </Card.Body>
    </Card>
  );
}

export default SCard;