import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import Email from '../components/main-components/sub-email'


const SubEmail = () => {
  return (
    <>
      <Email />
    </>
  );
};

export default SubEmail;