import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import '../css/perfil.css'
import OffCanvasalt from './OffalterarUser';
import OffCanvasaltEmp from './OffalterarEmp';
import OffCanvasaltPass from './OffalterarSenha';
import OffCanvasaltTel from './OffalterarTel';



function InputPerfil() {

  const [userData, setUserData] = useState(null);
  const [userUserName, setUserName] = useState(null);
  const [userCompanyurl, setCompanyurl] = useState(null);
  const [userCompanyName, setCompanyName] = useState(null);
  const [userEmail, setEmail] = useState(null);
  const [userTel, setTel] = useState(null);
 


  const [apiData, setApiData] = useState(null);


  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = Cookies.get('access_token');

      if (!accessToken) {
        console.error('Token não encontrado');
        return;
      }

      try {
        const response = await fetch('https://api2.cateyecyber.com/user_data', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          referrerPolicy: "unsafe-url" 
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Dados recebidos:', data);
          setApiData(data);

          // Conversão de 'scan' de string para objeto JSON
          const scanData = JSON.parse(data.scan);  // Transformando a string JSON em objeto

          // Atualizando os estados com os dados recebidos
          setCompanyurl(data.company_url);
          setCompanyName(data.company_name);
          setUserName(data.username);
          setTel(data.phone);
          setEmail(data.email);
        

      
       

        } else {
          console.error('Erro ao chamar a API:', await response.json());
        }
      } catch (error) {
        console.error('Erro na requisição de dados do usuário:', error);
      }
    };

    fetchUserData();
  }, []);






  return (
    <>
      <div className='input'>
            <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Seu Username:</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder={userUserName}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />

            <OffCanvasalt/>
          </InputGroup>

          
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Seu Telefone:</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder={userTel}
              aria-label="Phone"
              aria-describedby="basic-addon1"
            />

            <OffCanvasaltTel/>
          </InputGroup>

       


          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Sua Senha:</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder="Melhor não mostrar esse"
              aria-label="Password"
              aria-describedby="basic-addon1"
              type="password"
            />

            <OffCanvasaltPass/>
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Nome da empresa:</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder={userCompanyName}
              aria-label="Password"
              aria-describedby="basic-addon1"
              type="password"
            />

            <OffCanvasaltEmp/>  
          </InputGroup>

          

        
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Seu Email:</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder={userEmail}
              aria-label="Phone"
              aria-describedby="basic-addon1"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Url da empresa</InputGroup.Text>
            <Form.Control
              disabled={true}
              placeholder={userCompanyurl}
              aria-label="Password"
              aria-describedby="basic-addon1"
              type="password"
            />
          </InputGroup>


          </div>

     
    </>
  );
}

export default InputPerfil;