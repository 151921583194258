import React from 'react';
import '../css/sidebar.css'
import '../css/app.css'
import Home from '../components/main-components/home';


const HomePage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;