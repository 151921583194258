import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../App.css';
import '../../css/login2.css';
import cat from '../img/photos/cat.png';
import PL from '../img/photos/PerfilLogin.png';
import empresaLogo from '../img/photos/empresa.png';
import emaillogo from '../img/photos/email.png';
import Url from '../img/photos/URL.png';
import tel from '../img/photos/telefone.png';
import Cookies from 'js-cookie';

function Register() {
  const [empresa, setEmpresa] = useState('');
  const [url, setURL] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Função para validar o formato do e-mail
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Função para validar o formato da URL
  const validateURL = (url) => {
    const urlRegex = /^(https?:\/\/)?(www\.)?[^\s]+\.(com|br|net|org|gov|edu)(\.[^\s]+)?$/i;
    return urlRegex.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!empresa || !url || !name || !email || !telefone) {
      setError('Todos os campos são obrigatórios.');
      return;
    }

    // Validação de e-mail
    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }

    // Validação de URL
    if (!validateURL(url)) {
      setError('Por favor, insira uma URL válida. Exemplo: https://www.suaempresa.com ou suaempresa.com.br');
      return;
    }

    try {
      const response = await axios.post('https://api2.cateyecyber.com/register', {
        company_name: empresa,
        company_url: url,
        username: name,
        email: email,
        phone: telefone,
      });

      if (response.status === 200) {
        setSuccessMessage(`Usuário registrado com sucesso. Suas Credenciais de acesso foram enviadas para o email cadastro, Seja bem vindo a CatEye!`);
        setError('');
        Cookies.set('user_id', userId);
        setShowModal(true);
      } else {
        setError('Erro ao registrar usuário');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Erro ao registrar usuário:', error);
      setError('Erro ao registrar usuário. Por favor, tente novamente, ou verifique se esse email já foi registrado');
      setSuccessMessage('');
    }
  };

  const handleModalClose = async () => {
    setShowModal(false);
    setIsLoading(true); 
    
    try {
      const response = await axios.post('https://api2.cateyecyber.com/analyze', {
        email: email,
        company_url: url,
      });

      if (response.status === 200) {
        setSuccessMessage('Análise concluída com sucesso.');
      } else {
        setError('Erro ao realizar a análise.');
      }
    } catch (error) {
      console.error('Erro ao realizar a análise:', error);
      setError('Erro ao realizar a análise. Por favor, tente novamente.');
    }

    setIsLoading(false); // Remove a tela de loading
    navigate('/login');
  };

  return (
    <div className='blogin'>
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div>
                  <div className="card" id='logincard'>
                    <img className='cat' src={cat} alt="Logo" />
                    <div className="card-body" id='cardbody'>
                      <div className='logincard2'>
                        <h2 className='logintext2'>Registre-se</h2>
                        <div className='bar'></div>
                      </div>
                      <div className='CardInput2'>
                        <div className="m-sm-3">
                          <form onSubmit={handleSubmit} className='formcss'>
                            <div className='gridform'>
                              <div className="mb-3" id='ajuste'>
                                <label className="form-label">Nome da Empresa</label>
                                <div className='comboform'>
                                  <img className='PL' src={empresaLogo} alt="Logo" />
                                  <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    name="Empresa"
                                    id='formempresa'
                                    value={empresa}
                                    onChange={(e) => setEmpresa(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="mb-3" id='ajuste'>
                                <label className="form-label">URL da Empresa</label>
                                <div className='comboform'>
                                  <img className='PL' src={Url} alt="Logo" />
                                  <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    name="URL"
                                    id='formurl'
                                    value={url}
                                    onChange={(e) => setURL(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="mb-3" id='ajuste'>
                                <label className="form-label">Nome</label>
                                <div className='comboform'>
                                  <img className='PL' src={PL} alt="Logo" />
                                  <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    name="Name"
                                    id='formname'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="mb-3" id='ajuste'>
                                <label className="form-label">E-mail</label>
                                <div className='comboform'>
                                  <img className='PL' src={emaillogo} alt="Logo" />
                                  <input
                                    className="form-control form-control-lg"
                                    type="email"
                                    name="Email"
                                    id='formemail'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="mb-3" id='ajuste'>
                                <label className="form-label">Telefone</label>
                                <div className='comboform'>
                                  <img className='PL' src={tel} alt="Logo" />
                                  <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    name="Telefone"
                                    id='formtelefone'
                                    value={telefone}
                                    onChange={(e) => setTelefone(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-grid gap-2 mt-3" id='buttonLogin'>
                              <button type="submit" className="btn btn-lg btn-primary" id='btn-submit2'>Registrar</button>
                            </div>
                            {error && <div className="text-danger">{error}</div>}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div className='blogin'>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Carregando...</p>
          </div>
        )}
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalTitle">Registro Bem-Sucedido</h5>
                <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>{successMessage}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleModalClose}>Ir para Login</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
