import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../components/js/AuthContext';
import '../../App.css';
import Cookies from 'js-cookie';
import '../../css/login2.css';
import cat from '../img/photos/cat.png';
import PL from '../img/photos/PerfilLogin.png';
import senha from '../img/photos/Senha.png';
import olhologin from '../img/photos/olhologin.png';
import { Link } from 'react-router-dom';
import { useDashboardAuth } from '../../components/js/dashAuth'; 


function SignIn({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const { accessDashboard } = useDashboardAuth(); 

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();



    try {
      const response = await axios.post('https://api2.cateyecyber.com/login', { email, password });

      if (response.status === 200) {
        const responseData = response.data;
        const { access_token } = responseData;

        Cookies.set('email', email);
        Cookies.set('password', password);
        Cookies.set('access_token', access_token);
        console.log('Usuário autenticado');
        
        if (email === 'admin@cateyecyber.com') {
          login();
          navigate('/admin');  // Redireciona para a página de administração
        } else {
          accessDashboard()
          navigate('/dashboard');  // Redireciona para o dashboard
        }
      } else {
        setError('Credenciais inválidas');
      }
      


    } catch (error) {
      console.error('Erro ao autenticar usuário:', error);
      setError('Erro ao autenticar usuário. Por favor, tente novamente.');
    }
  };

  return (
    <div className='blogin'>
      <main className="d-flex w-100">
        <div className="container d-flex flex-column">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div>
                  <div className="card" id='logincard'>
                    <img className='cat' src={cat} alt="Logo" />
                    <div className="card-body" id='cardbody'>
                      <div className='logincard'>
                        <h2 className='logintext'>Login</h2>
                        <div className='bar'></div>
                      </div>
                      <div className='CardInput'>
                        <div className="m-sm-3">
                          <form onSubmit={handleSubmit} className='formcss'>
                            <div className="mb-3">
                              <label className="form-label">EMAIL</label>
                              <div className='comboform'>
                                <img className='PL' src={PL} alt="Logo" />
                                <input
                                  className="form-control form-control-lg"
                                  type="email"
                                  name="email"
                                  id='formemail'
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="form-label">SENHA</label>
                              <div className='comboform'>
                                <img className='PL' src={senha} alt="Logo" />
                                <input
                                  className="form-control form-control-lg"
                                  type="password"
                                  name="password"
                                  id='formsenha'
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <img className='olhologin' src={olhologin} alt="Logo" />
                              </div>
                            </div>
                            <div className="d-grid gap-2 mt-3" id='buttonLogin'>
                              <button type="submit" className="btn btn-lg btn-primary" id='btn-submit'>
                                Entrar
                              </button>
                            </div>
                            {error && <div className="text-danger">{error}</div>}
                            <h5 className='textoinferior'>
                              <Link to="/troca" className='h5troca'>esqueceu a senha?</Link>
                            </h5>

                            <h5 className='textoinferior2'>
                            *Dashboard disponivel apenas na versão desktop*
                            </h5>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SignIn;
