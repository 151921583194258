import React from 'react';
import Sidebar from '../sidebar'
import '../../App.css';
import '../../css/sidebar.css'
import IconDropdown from '../dropdown'
import TabelaEstatica from '../tabelacheck';
import check from '../img/photos/checklist.png'
import ProgresBar from '../progressbar';

function Checklist() {
  return (
    <div>
    <head>
      <link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
    </head>
      <div className="side">
        <Sidebar />
        <div className='didigod'>
        <div className='topbar'> <h4 className='PR'>Checklist de Segurança <img className='imgsub' src={check} alt="Logo" /></h4><IconDropdown/></div>
        <div className='content'>   
          <h5 className='checklisttext'>O Ckecklist de Segurança é uma série de tarefas básicas de cibersegurança que a CatEye sugere que sua organização realize para que você e sua  equipe se tornem mais seguros. Indique se as ações já foram implementadas no botão de ver mais.</h5>
          <h5>Progresso</h5>
          <ProgresBar  />
          <TabelaEstatica />
        </div>
        </div>
      </div>
    </div>
  );
}

export default Checklist;
