import React from 'react';
import HNavbar from '../nav';
import '../../css/nav.css'
import Cfooter from '../Hfooter';
import EmailText from '../textsubemail';



function Email() {
  return (
    <div className='home'>
      <HNavbar className='bgc' />
      <EmailText />
      <Cfooter />

    </div>
  );
}

export default Email;