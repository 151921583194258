import { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'

function OffCanvasPS({ name, status, toggleStatus, ...props }) {

  const [show, setShow] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const allChecked = checkboxes.checkbox1;
    toggleStatus(allChecked);
  }, [checkboxes, toggleStatus]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes(prevState => ({ ...prevState, [name]: checked }));
  };


  return (
    <>
      <img className='olho'onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offteste'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='offtitle'>PROTEÇÃO A SENHAS</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodymfa'>
        <h4 className='subtitulo'>Entenda</h4>
          <p><b className='boldlist'>Exigir senhas rigorosas:</b> Esta parte da recomendação sugere a implementação de regras estritas para a criação de senhas. Uma senha considerada forte geralmente inclui uma combinação de letras maiúsculas e minúsculas, números e símbolos especiais. O objetivo é tornar as senhas difíceis de serem adivinhadas ou descobertas por ataques automatizados.
          
          </p>
          <p><b className='boldlist'>Garantir a troca de todas as senhas a cada 90 dias:</b> Aqui, a recomendação é que as senhas sejam mudadas periodicamente, neste caso, a cada 90 dias. A razão para trocar as senhas regularmente é minimizar os riscos associados ao possível comprometimento de senhas. Caso uma senha seja exposta, o tempo durante o qual um invasor pode utilizá-la é limitado, reduzindo assim o potencial para danos</p>

          <b>Marque as ações implementadas</b>
          <div className='backgroundinputoff'>
          <input
          className='testeinput'
            type="checkbox"
            name="checkbox1"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />  Adotamos uma política que exige senhas com uma combinação de letras maiúsculas e minúsculas, números e símbolos especiais e garantimos a sua troca a cada 90 dias.
          Todas as mídias portáteis são protegidas com senha
          </div>
          <br/>
          <br/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffPS({status, toggleStatus}) {
  return (
    <>
      {[ 'end'].map((placement, idx) => (
        <OffCanvasPS  
        key={idx}
        placement={placement}
        name={placement}
        status={status}
        toggleStatus={toggleStatus} 
        />
      ))}
    </>
  );
}

export default OffPS;