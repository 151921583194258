import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import olho from '../components/img/photos/olho.png'
import '../css/offcanvas.css'
import baixa from '../components/img/photos/logobaixo.png'

function OffCanvasExampleSS({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <img className='olho' onClick={handleShow} src={olho} alt="Logo" />

      <Offcanvas show={show} onHide={handleClose} {...props} className='offcanvasdash'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Vulnerabilidades</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bodydash'>
          <h5 className='subtitledash'>Comportamento Suspeito (Suspicious Status)</h5>

          <div className='Blocodash'>
            <div className='subblocdash'>
              <b className='titledash'>Gravidade</b>
              <p className='textdash'> <img className='imgbaixa' src={baixa} alt="Logo" /> Baixa</p>
            </div>

            <div className='subblocdash'>
              <b className='titledash' >Ativos</b>
              <p className='textdash'>1</p>
            </div>

            <div className='subblocdash'>
              <b className='titledash' >Primeira Detecção</b>
              <p className='textdash'>17/07/24</p>
            </div>

            <div className='subblocdash'>
              <b className='titledash' >Última Detecção</b>
              <p className='textdash'>17/07/24</p>
            </div>

          </div>

          <div className='textdescricao'>
            <b>O que significa "Suspicious Status"?</b>
            <p>Quando um scan de segurança identifica um "Suspicious Status", isso pode significar que o site apresenta algum comportamento ou característica que, embora não seja imediatamente uma ameaça, é considerado fora do normal e pode indicar que algo está errado. Isso poderia ser um sinal de que:
            
            </p>
          </div>

          <div className='listarecomendacao'>
            <ul>
              <li className=''><b>1.	Atividade Anormal:  </b>O site pode estar realizando atividades que não são comuns para um site legítimo, como redirecionamentos incomuns ou execução de scripts que não são normalmente encontrados em sites seguros.</li>
              <br />
              <li className=''><b>2.	Configurações Incomuns:  </b>Algumas configurações no servidor ou na aplicação podem estar diferentes do padrão, o que pode levantar suspeitas sobre a integridade do site.</li>
              <br />
              <li className=''><b>3.	Presença de Conteúdo Potencialmente Perigoso:  </b>O site pode estar hospedando arquivos, scripts ou códigos que não são geralmente encontrados em sites seguros. Isso pode incluir elementos que são frequentemente usados por atacantes para comprometer a segurança.</li>
            </ul>
          </div>


          <b>Como Resolver:</b>
          <div className='listarecomendacao'>
            <ul>
              <li className='lista'><a>Investigue o que está causando o alerta.</a></li>
              <li className='lista'><a>Remova ou ajuste o elemento suspeito.</a></li>
              <li className='lista'><a>Rode um novo scan para verificar se o problema foi resolvido.</a></li>
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function OffDashSS() {
  return (
    <>
      {['end'].map((placement, idx) => (
        <OffCanvasExampleSS key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default OffDashSS