import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDashboardAuth } from './dashAuth';

const DashboardPrivateRoute = ({ children }) => {
  const { isDashboardUser } = useDashboardAuth();

  if (isDashboardUser) {
    return children;
  }

  return <Navigate to="/login" />;
};

export default DashboardPrivateRoute;