import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import '../../css/admin.css';
import Cookies from 'js-cookie';  // Para pegar o token JWT dos cookies, caso esteja usando autenticação

function AdminUserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {

      const accessToken = Cookies.get('access_token');

      try {
        const response = await axios.get('https://api2.cateyecyber.com/users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          referrerPolicy: "unsafe-url" 
        });
        setUsers(response.data);  // Define os usuários retornados na resposta
        setLoading(false);
      } catch (err) {
        setError('Erro ao buscar usuários.');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []); // O array vazio significa que este useEffect será chamado apenas uma vez ao montar o componente.

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container2">
      <h2>Lista de Usuários</h2>
      <div className='table'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company Name</th>
              <th>Company URL</th>
              <th>Username</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Registration Date</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              let totalScore = 'N/A';
              try {
                const scanObject = typeof user.scan === 'string' ? JSON.parse(user.scan) : user.scan;
                if (scanObject && typeof scanObject === 'object' && scanObject.total_score !== undefined) {
                  totalScore = scanObject.total_score;
                }
              } catch (e) {
                console.error('Erro ao analisar o campo scan:', e);
              }
              return (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.company_name}</td>
                  <td>{user.company_url}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.registration_date}</td>
                  <td className='jsonscan'>{totalScore}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminUserList;
