import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/nav.css'
import  navimg from '../components/img/photos/cateye.png'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from 'reactstrap';

function HNavbar(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className='backgroundnav'>
      <Navbar {...args}>
        <NavbarBrand href="/"><img width={63} height={55} alt='img' src={navimg}></img></NavbarBrand>
        <div className='m'>
          <UncontrolledDropdown nav inNavbar>
            <div className='SignIn'>
              <DropdownToggle nav caret className='login'>
                Login
              </DropdownToggle>
            </div>
            <DropdownMenu right>
              <DropdownItem href='/login'>Login</DropdownItem>
              <DropdownItem href='/reg'>Registre-se</DropdownItem>
             
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavbarToggler onClick={toggle} />
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/solucoes">Soluções CatEye</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contrat">
                Pagina de contratação
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/pep">
                Politica e Privacidade
              </NavLink>
            </NavItem>
          
          </Nav>

        </Collapse>

      </Navbar>
    </div>
  );
}

export default HNavbar;