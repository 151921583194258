import '../css/card3.css'
import MarketText from './market-text';


function Marketcard() {
  return (
    <div>
      <div className="backgrounds">
        <div className="cardSE">
          <MarketText />
        </div>
      </div>

    </div>

  );
}

export default Marketcard;