import React from 'react';
import Sidebar from '../sidebar'
import '../../App.css';
import '../../css/sidebar.css'
import CopilotCard from '../CopilotCard';



function CopilotComponent() {
  return (
    <div>
      <div className="side">
        <Sidebar />
        <div className='content'>
          <CopilotCard />
        </div>
      </div>
    </div>
  );
}

export default CopilotComponent;
