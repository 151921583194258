import React from 'react';
import TradeLogin from '../components/main-components/TrocaSenha';
import feather from 'feather-icons';
import TesteDb from './teste';

const TradeLoginPage = () => {

  feather.replace();

  return (
    <>
      <TradeLogin />

    </>
  );
};

export default TradeLoginPage;