import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import '../css/dash.css';
import Grafico1 from './solidgauge';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Mapa from './mapa';
import pdf from './img/photos/pdf.png'
import OffDefault from '../offcanvasdash/offcanvasdash'
import critica from './img/photos/logocritica.png'
import alta from './img/photos/logoAlta.png'
import media from './img/photos/logomedio.png'
import baixa from './img/photos/logobaixo.png'
import cadeadografico from './img/photos/cadeadografico.png'
import Dropdown from 'react-bootstrap/Dropdown';
import OffDashSS from '../offcanvasdash/offcanvasdash copy';
import OffDashVS from '../offcanvasdash/offcanvasdasVS';
import OffDashCES from '../offcanvasdash/offcanvasdashCES';
import OffDashRS from '../offcanvasdash/offcanvasdashRS';
import OffDashDES from '../offcanvasdash/offcanvasdashDES';
import OffDashRP from '../offcanvasdash/offcanvasdashRP';
import OffDashVT from '../offcanvasdash/offcanvasdashVT';
import OffDashEmail from '../offcanvasdash/offcanvasdashEmail';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PerfilModal from './perfil';



function UserData() {
 
}


// Importe um módulo para importar dinamicamente JSONs
function importJson(fileName) {
  return import(`../json/${fileName}.json`).then(module => module.default);
}

function Cardp() {
  const [userData, setUserData] = useState(null);
  const [jsonFileName, setJsonFileName] = useState('youtube.com');

  const [apiData, setApiData] = useState(null);
  const [company_url, setCompany_url] = useState('');
  const [scanscore, setScanscore] = useState('');
  const [scandate, setScandate] = useState('');
  const [scanCritica, setScanCritica] = useState('');
  const [scanAlta, setScanAlta] = useState('');
  const [scanMedia, setScanMedia] = useState('');
  const [scanBaixa, setScanBaixa] = useState('');
  const [email, setemail] = useState('');
  const [status, setstatus] = useState('');
  const [scanEmailBranch, setScanEmailBranch] = useState('');
  const [historicoData, setHistoricoData] = useState([
    { name: 'Jan 24', pv: 1 },
  ]);


  
  const handleInputChange = (event) => {
    setJsonFileName(event.target.value);
  };



 

  useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = Cookies.get('access_token');

      if (!accessToken) {
        console.error('Token não encontrado');
        return;
      }

      try {
        const response = await fetch('https://api2.cateyecyber.com/user_data', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          referrerPolicy: "unsafe-url" 
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Dados recebidos:', data);
          setApiData(data);

          // Conversão de 'scan' de string para objeto JSON
          const scanData = JSON.parse(data.scan);  // Transformando a string JSON em objeto

          // Atualizando os estados com os dados recebidos
          setCompany_url(data.company_url);
          setemail(data.email);
          setScanscore(scanData.total_score);  // Acessando 'total_score' dentro de 'scanData'
          setScandate(scanData.scan_date);  // Acessando 'scan_date' dentro de 'scanData'
          setScanCritica(scanData.severity_counts.critica);  // 'severity_counts' dentro de 'scanData'
          setScanAlta(scanData.severity_counts.alta);
          setScanMedia(scanData.severity_counts.media);
          setScanBaixa(scanData.severity_counts.baixa);

          // Atualizando o status do scan com os dados parseados
          setScanEmailBranch(scanData);
          setstatus(scanData);

        
          setHistoricoData(prevHistoricoData => {
            const alreadyExists = prevHistoricoData.some(item => item.name === scanData.scan_date);
            if (!alreadyExists) {
              return [...prevHistoricoData, { name: scanData.scan_date, pv: scanData.total_score }];
            }
            return prevHistoricoData;
          });

        } else {
          console.error('Erro ao chamar a API:', await response.json());
        }
      } catch (error) {
        console.error('Erro na requisição de dados do usuário:', error);
      }
    };

    fetchUserData();
  }, []);




  const handleNewScan = async () => {
    const user_id = Cookies.get('user_id'); // Obtém o user_id do cookie

  

    try {
      const response = await fetch('https://api2.cateyecyber.com/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, company_url }),
        referrerPolicy: "unsafe-url" 
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Re-scan realizado com sucesso:', data);
        toast.success('Re-scan realizado com sucesso!');

        const scanData = JSON.parse(data.scan);  
      
    
        setScanscore(scanData.total_score);  // Acessando 'total_score' dentro de 'scanData'
        setScandate(scanData.scan_date);  // Acessando 'scan_date' dentro de 'scanData'
        setScanCritica(scanData.severity_counts.critica);  // 'severity_counts' dentro de 'scanData'
        setScanAlta(scanData.severity_counts.alta);
        setScanMedia(scanData.severity_counts.media);
        setScanBaixa(scanData.severity_counts.baixa);

      
        setHistoricoData(prevHistoricoData => {
            const alreadyExists = prevHistoricoData.some(item => item.name === scanData.scan_date);
            if (!alreadyExists) {
              return [...prevHistoricoData, { name: scanData.scan_date, pv: scanData.total_score }];
            }
            return prevHistoricoData;
        });
        // Atualize outras variáveis de estado conforme necessário
      } else {
        console.error('Erro ao realizar o re-scan:', await response.json());
      }
    } catch (error) {
      console.error('Erro na requisição para re-scan:', error);
    }
  };

 

  const marcadores = [
    { lat: 51.505, lng: -0.09, texto: 'Londres' },
    { lat: 48.8566, lng: 2.3522, texto: 'Paris' },
    { lat: 40.7128, lng: -74.006, texto: 'Nova Iorque' },
  ];

 

  useEffect(() => {
    // Carrega o JSON dinamicamente com base no nome do arquivo
    importJson(jsonFileName)
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error(`Erro ao carregar JSON ${jsonFileName}:`, error);
      });
  }, [jsonFileName]);

 

  if (!userData) {
    return <div>Loading...</div>;
  }

  const filteredVulnerabilities = [
    { name: 'HTTPS Status', severity: 'Crítica', logo: <img className='logocrittable' src={critica} alt="Logo" />, ativos: typeof scanEmailBranch.https_status === 'object' ? scanEmailBranch.https_status.mensagem : scanEmailBranch.https_status, component: <OffDefault />, status: typeof scanEmailBranch.https_status === 'object' ? scanEmailBranch.https_status.status : scanEmailBranch.https_status, },
    { name: 'Suspicious Status', severity: 'Crítica', logo: <img className='logocrittable' src={critica} alt="Logo" />, ativos: typeof scanEmailBranch.suspicious_status === 'object' ? scanEmailBranch.suspicious_status.mensagem : scanEmailBranch.suspicious_status, component: <OffDashSS />, status: typeof scanEmailBranch.suspicious_status === 'object' ? scanEmailBranch.suspicious_status.status : scanEmailBranch.suspicious_status, },
    { name: 'Certificate_Expiry_Status', severity: 'Média', logo: <img className='logomediatable' src={media} alt="Logo" />, ativos: typeof scanEmailBranch.certificate_expiry_status === 'object' ? scanEmailBranch.certificate_expiry_status.mensagem : scanEmailBranch.certificate_expiry_status, component: <OffDashCES />, status:typeof scanEmailBranch.certificate_expiry_status === 'object' ? scanEmailBranch.certificate_expiry_status.status : scanEmailBranch.certificate_expiry_status },
    { name: 'Redirect Status', severity: 'Alta', logo: <img className='logoaltatable' src={alta} alt="Logo" />, ativos: typeof scanEmailBranch.redirect_status === 'object' ? scanEmailBranch.redirect_status.mensagem : scanEmailBranch.redirect_status, component: <OffDashRS />, status: typeof scanEmailBranch.redirect_status === 'object' ? scanEmailBranch.redirect_status.status : scanEmailBranch.redirect_status },
    { name: 'Domain_Expiry_Status', severity: 'Média', logo: <img className='logomediatable' src={media} alt="Logo" />, ativos: typeof scanEmailBranch.domain_expiry_status === 'object' ? scanEmailBranch.domain_expiry_status.mensagem : scanEmailBranch.domain_expiry_status, component: <OffDashDES />, status:typeof scanEmailBranch.domain_expiry_status === 'object' ? scanEmailBranch.domain_expiry_status.status : scanEmailBranch.domain_expiry_status },
    { name: 'Registration Public', severity: 'Baixa', logo: <img className='logomediatable' src={baixa} alt="Logo" />, ativos: typeof scanEmailBranch.registration_public === 'object' ? scanEmailBranch.registration_public.mensagem : scanEmailBranch.registration_public, component: <OffDashRP />, status: typeof scanEmailBranch.registration_public === 'object' ? scanEmailBranch.registration_public.status : scanEmailBranch.registration_public },
    { name: 'VT Message', severity: 'Crítica', logo: <img className='logocrittable' src={critica} alt="Logo" />, ativos: typeof scanEmailBranch.vt_message === 'object' ? scanEmailBranch.vt_message.mensagem : scanEmailBranch.vt_message, component: <OffDashVT />, status: typeof scanEmailBranch.vt_message === 'object' ? scanEmailBranch.vt_message.status : '⚠️' },
    { name: 'Email_Breach_Status', severity: 'Crítica', logo: <img className='logocrittable' src={critica} alt="Logo" />, ativos: typeof scanEmailBranch.email_breach_status === 'object' ? scanEmailBranch.email_breach_status.mensagem : scanEmailBranch.email_breach_status, component: <OffDashEmail />, status: typeof scanEmailBranch.email_breach_status === 'object' ? scanEmailBranch.email_breach_status.status : scanEmailBranch.email_breach_status }
  ].filter(item => Object.keys(scanEmailBranch).includes(item.name.toLowerCase().replace(' ', '_')));


  return (
    
    <div>

      <div className='TopDash'>

      <Accordion defaultActiveKey="1" className='accordion' >
      <Accordion.Item eventKey="1" className='accordionitem'>
        <Accordion.Header className='accordionheader2'> <h4 className='accordionheader'>{company_url}</h4></Accordion.Header>
        
      </Accordion.Item>
    </Accordion>
        
        <div className='griddireito'>
        <h6 className='scanhistory'>Ultimo scan realizado em: {scandate}</h6>
        <Button variant="secondary" size="lg" className='disablebutton' onClick={handleNewScan}>
        Novo Scan  
        </Button>
        <ToastContainer />
        </div>
        </div>
      


      <div className='fistpartdash'>
      <Card className='Card1ssc'>
            <Card.Body className='card1g'>
              <h5 className='riskscore'>Risk Score</h5>
              <div className='grafico-texto'>
                <div className='grafico1'>
                  <Grafico1 value={scanscore} threshold={70} />
                </div>
              </div>
           
            </Card.Body>
          </Card>

          
        
          <div className='VulCard'>
          <h6 className='SubtitleVE'>Vulnerabilidades encontradas por gravidade</h6>
          
             
          <div className='subtitlevulnerabilidades'>
          <h6 className='SubtitleVES'><img className='logocrit' src={critica} alt="Logo" /><h6 className='SubtitleVEC'>{scanCritica} </h6>  Critica</h6>
          <h6 className='SubtitleVESA'><img className='logoalta' src={alta} alt="Logo" /><h6 className='SubtitleVEC'>{scanAlta} </h6>  Alta</h6>
          <h6 className='SubtitleVESM'><img className='logomedia' src={media} alt="Logo" /><h6 className='SubtitleVEC'>{scanMedia} </h6>  Média</h6>
          <h6 className='SubtitleVESB'><img className='logomedia' src={baixa} alt="Logo" /><h6 className='SubtitleVEC'>{scanBaixa} </h6>  Baixa </h6>
          </div>


          <Card className='CardRisco'>
            <Card.Body className='subcardrisco'>
            <img className='imgrisco' src={pdf} alt="Logo" />
            <div className='textrisco'>
              <h3 className='h3risco'>Avaliação de Risco Cibernético</h3>
              <p className='prisco'>O relátorio de Avaliação de risco da CatEye fornece uma visão<br/>
              personalizada do risco da sua organização </p>
            </div>
           <a href="https://drive.google.com/file/d/1wfAAFpFeVDeJK7Ff1D4q7O9pTrtNPOMI/view?usp=sharing" target='blank' className='download2'> <Button variant="primary" className='buttonrisco'>Download</Button> </a>
            </Card.Body>
          </Card>



          </div>

          </div>


      <Card style={{ width: '98%' }} className='tablecard' >
        <Card.Body>
          <Card.Title>
            <h5 className='titlelist'>Veja todas as vulnerabilidades que afetam sua empresa</h5>
          </Card.Title>

          <table border="0" className='table'>
            <thead>
              <tr>
                <th>Vulnerabilidade</th>
                <th>Gravidade</th>
                <th>Status</th>
                <th>mensagem</th>
                <th>Primeira Detecção</th>
                <th>Ultima Detecção</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>

            {filteredVulnerabilities.map((vulnerability, index) => (
                <tr key={index}>
                  <td>{vulnerability.name}</td>
                  <td>{vulnerability.logo} {vulnerability.severity}</td>
                  <td>{vulnerability.status}</td>
                  <td>{vulnerability.ativos}</td>
                  <td>{scandate}</td>
                  <td>{scandate}</td>
                  <td>{vulnerability.component}</td>
                </tr>
              ))}

             
              {scanEmailBranch && scanEmailBranch.email_breaches && scanEmailBranch.email_breaches.length > 0 ? (
                scanEmailBranch.email_breaches.map((breach, index) => {
                  const [sourcePart, datePart] = breach.split(", Data: ");
                  const sourceName = sourcePart.replace("Fonte: ", "").trim();
                  const breachDate = datePart ? datePart.trim() : 'N/A';

                  return (
                    <tr key={index}>
                      <td>Breach - {sourceName} {index + 1}</td>
                      <td><img className='logoaltatable' src={alta} alt="Logo" /> Alta</td>
                      <td></td>
                      <td>*vazamento identificado*</td>
                      <td>{breachDate}</td>
                      <td>{breachDate}</td> {/* Incluindo a data */}
                      <td><OffDashEmail /></td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">Nenhuma violação de e-mail encontrada</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      <div className='CardS'>
        <div className="d-flex justify-content-around" id='SSC' >
         

         <div className='topcard3ssc'>
          <h5>Distribuição dos Ativos</h5>
              <Mapa marcadores={marcadores} />
          </div>

          <div className='topcard2ssc'>
          <h5>Vulnerabilidades ao longo do tempo <img className='' src={cadeadografico} alt="Logo" /></h5>
          <Card className='RCA'>
            <Card.Body>
              <h5>Gráfico de Histórico</h5>
              <div style={{ width: '100%', height: 355 }} className='Card2ssc'>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={historicoData} margin={{ top: 5, right: 50, left: 1, bottom: 5 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card.Body>
          </Card>
          
          
          <PerfilModal/>



          </div>
        </div>
      </div>
    </div>
  );
}

export default Cardp;
